import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { LATERALSMALL } from 'app/theme/spacing';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  modal: {
    width: moderateScale(600),
    height: moderateScale(337),
    justifyContent: 'space-around',
    zIndex: 999
  },
  cat: { alignItems: 'center' },
  body: {
    padding: moderateScale(10)
  },
  title: {
    marginVertical: moderateScale(10)
  },
  bodyText: {
    marginVertical: moderateScale(2)
  },
  buttonWrapper: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginVertical: moderateScale(5),
    zIndex: 99
  },
  cancelBtn: {
    width: '30%',
    borderRadius: 10,
    paddingVertical: moderateScale(10),
    paddingHorizontal: moderateScale(20),
    alignSelf: 'center',
    backgroundColor: Colors.greyFour,
    marginHorizontal: LATERALSMALL / 2
  },
  reloadButton: { width: '30%', marginHorizontal: LATERALSMALL / 2 },
  reloadText: { fontSize: moderateScale(14) }
});
