import React from 'react';
import { Pressable } from 'react-native';

import MenuIcon from '@assets/svg/MenuIcon';
import CloseIcon from '@assets/svg/CloseIcon';
import { Colors } from '@theme';
import {
  useStore,
  toggleMenuModalHandler,
  menuModalSelector,
  toggleNotificationsModalHandler,
  notificationsModalOpenSelector
} from '@store/index';

import { styles } from './styles';

const MenuButton = (): JSX.Element => {
  // store
  const menuModalOpen = useStore(menuModalSelector);
  const toggleMenu = useStore(toggleMenuModalHandler);

  const notificationsModalOpen = useStore(notificationsModalOpenSelector);
  const toggleNotificationsModal = useStore(toggleNotificationsModalHandler);
  // functions
  function handleMenuButton(): void {
    if (notificationsModalOpen) {
      toggleNotificationsModal();
    }
    toggleMenu();
  }

  // render
  return (
    <Pressable
      hitSlop={20}
      style={[styles.menuButton, menuModalOpen && styles.activeMenu]}
      onPress={handleMenuButton}>
      {menuModalOpen ? <CloseIcon fill={Colors.limeGreen} /> : <MenuIcon />}
    </Pressable>
  );
};

export default MenuButton;
