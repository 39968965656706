import React from 'react';
import Animated, { FadeInRight, FadeOutRight } from 'react-native-reanimated';
import { AxiosError } from 'axios';
import { Keyboard, View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import { passwordRegex } from '@utils/validations';
import EmailInputIcon from '@assets/svg/EmailInputIcon';
import { useLogin } from '@services/useLogin';
import Button from '@atoms/Button';
import toast from '@utils/toast';

import { styles } from '../styles';
import NeedHelp from '../NeedHelp';
import { Step2Props } from './types';

const Step1 = ({ control, getValues, errors, isEmpty, handleSubmit, setAuthToken, nextStep }: Step2Props) => {
  const i18n = useTranslations();
  const login = useLogin();

  function onSubmit(values: any): void {
    Keyboard.dismiss();

    login.mutate(
      { ...values, remember: true },
      {
        onSuccess: (response) => {
          const token = response.access_token;
          setAuthToken(token);
          nextStep();
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = i18n.t(error?.response?.data.translate);
            toast.danger({
              title: message,
              icon: 'error'
            });
          }
        }
      }
    );
  }

  return (
    <Animated.View style={styles.wrapper} entering={FadeInRight} exiting={FadeOutRight}>
      <CustomText align="center" weight="interSemi" size="xlarge" color="limeGreen">
        {i18n.t('account_settings.delete_account_title_2')}
      </CustomText>

      <CustomText
        customStyle={styles.description}
        align="center"
        weight="interRegular"
        size="big"
        color="white">
        {i18n.t('account_settings.delete_account_description_5')}
      </CustomText>

      <Field
        icon={EmailInputIcon}
        name="emailOrUsername"
        type="email-address"
        control={control}
        placeholder={i18n.t('login.email_placeholder').toString()}
        getValues={getValues}
        rules={{
          required: i18n.t('login.required').toString()
        }}
        error={errors}
      />

      <Field
        icon={PasswordInputIcon}
        containerStyles={styles.passInput}
        name="password"
        control={control}
        placeholder={i18n.t('register.password_placeholder').toString()}
        rules={{
          pattern: {
            value: passwordRegex,
            message: i18n.t('register.password_min_lenght_error')
          }
        }}
        secure
        getValues={getValues}
        error={errors}
      />

      <Button
        loading={login?.isLoading}
        disabled={isEmpty}
        customStyle={styles.deleteAccountActionButton}
        customTextStyle={styles.footerButtonText}
        onPress={handleSubmit(onSubmit)}>
        {i18n.t('account_settings.delete_account_button_action')}
      </Button>
      <View>
        <CustomText size="medium" weight="interRegular">
          <CustomText size="medium" weight="interBold">
            {i18n.t('account_settings.delete_account_disclaimer_bold') + ' '}
          </CustomText>
          {i18n.t('account_settings.delete_account_disclaimer')}
        </CustomText>
      </View>
      <NeedHelp />
    </Animated.View>
  );
};

export default Step1;
