import React, { FC } from 'react';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { View } from 'react-native';
import { numberToCurrency } from '@utils/numberToCurrency';
import { BankIcon } from '@assets/svg/bankIcon';
import { IconBadge } from '@molecules/IconBadge';
import { styles } from './styles';
import { IAvailableFundsProps } from './types';
import { InformationIcon } from '@assets/svg/InformationIcon';

export const AvailableFundsCard: FC<IAvailableFundsProps> = ({ value }): JSX.Element => {
  //hooks
  const i18n = useTranslations();

  //render
  return (
    <View style={styles.mainContainer}>
      <View style={styles.flexWrapper}>
        <IconBadge icon={<BankIcon />} />
        <CustomText weight="interBold" align="left" size="xmedium" customStyle={{ marginHorizontal: 10 }}>
          {i18n.t('dashboard.my_earnings.funds.available_funds')}
        </CustomText>
      </View>
      <CustomText weight="interBold" align="left" size="giant" customStyle={{ marginVertical: 10 }}>
        {numberToCurrency(Number(value))}
      </CustomText>
      <View style={[styles.flexWrapper, { opacity: value < 100 ? 1 : 0 }]}>
        <InformationIcon />
        <CustomText align="left" size="medium" color="warning" customStyle={{ marginHorizontal: 5 }}>
          {i18n.t('dashboard.my_earnings.funds.minimun_withdrawal_warning')}
        </CustomText>
      </View>
    </View>
  );
};
