import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import es from 'javascript-time-ago/locale/es.json';

import CustomText from '@atoms/CustomText';

// TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);
TimeAgo.addLocale(es);

interface TimeProps {
  children: string;
}

const Time = ({ children }: TimeProps) => (
  <CustomText weight="interSemi" align="left" size="xsmall" color="greyThree">
    {children.replace('minutes', 'mins')}
  </CustomText>
);

interface TimeCompProps {
  date: Date | number | string;
}

const TimeComp = (props: TimeCompProps) => {
  let { date } = props;

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return <ReactTimeAgo date={date} component={Time} />;
};

export default TimeComp;
