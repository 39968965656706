export interface IEarningStatCardProps {
  title: string;
  value: number;
  format?: BalanceStatsEnum;
}

export enum BalanceStatsEnum {
  DECIMAL_NUMBER = 'decimal',
  TIME_NUMBER = 'time',
  CURRENCY_NUMBER = 'currency'
}
