/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Animated, View, TextInput } from 'react-native';
import { Controller } from 'react-hook-form';

import CustomText from '@atoms/CustomText';
import { isDesktop, isMobile } from '@constants/platform';

import OTPInputDot from './CustomInputPasswordDot';
import { PIN_QUANTITY, SHAKE_DURATION, getShake } from './constants';
import styles from './styles';

interface Props {
  currentValue: string;
  pinQuantity?: number;
  name?: string;
  control: any;
  hasError?: boolean;
  hashedValue?: boolean;
  isSubmiting?: boolean;
  errorMessage?: string;
  accessibilityLabelValue?: string;
  setValue: (val: string) => void;
}

const CustomInputPassword = ({
  currentValue,
  name = '',
  control,
  hasError,
  hashedValue,
  pinQuantity,
  isSubmiting,
  errorMessage,
  accessibilityLabelValue = '',
  setValue,
}: Props) => {
  const [valuesArray, setValuesArray] = useState<string[]>([]);
  const [quantityToMap, setQuantityToMap] = useState<number[]>(PIN_QUANTITY);
  const shakeAnimation = useRef(new Animated.Value(0)).current;
  const errorAnimation = useRef(new Animated.Value(0)).current;

  const inputRef = useRef<TextInput>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 40);
  }, []);

  const translationY = errorAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [40, 0],
  });

  const handleFocus = () => {
    if (!inputRef.current) return;
    inputRef.current.focus();
    if (valuesArray.length === quantityToMap.length) {
      setValuesArray([]);
      setValue('');
      inputRef.current?.clear();
    }
  };

  const handleBlur = useCallback(() => {
    if (!inputRef.current) return;
    if (valuesArray.length === quantityToMap.length) inputRef.current.blur();
    else inputRef.current.focus();
  }, [valuesArray]);

  const errorText = useCallback(() => {
    Animated.timing(errorAnimation, {
      toValue: 1,
      delay: 300,
      duration: 200,
      useNativeDriver: isMobile,
    }).start();
  }, [errorAnimation]);

  const errorShake = useCallback(() => {
    const rightShake = getShake(shakeAnimation);
    const leftShake = getShake(shakeAnimation, true);
    Animated.sequence([
      rightShake,
      leftShake,
      rightShake,
      leftShake,
      rightShake,
      leftShake,
      rightShake,
      leftShake,
      Animated.timing(shakeAnimation, {
        toValue: 0,
        duration: SHAKE_DURATION,
        useNativeDriver: isMobile,
      }),
    ]).start();
  }, [shakeAnimation]);

  const handleValueChange = useCallback((changedValue: string) => {
    if (changedValue.length > quantityToMap.length) return;
    const valueToSet = changedValue.split('');
    setValuesArray(valueToSet);
    setValue(changedValue);
  }, []);

  const handleMakeArr = () => {
    if (pinQuantity) {
      const aux = [];
      for (let index = 0; index < pinQuantity; index + 1) {
        aux.push(index);
      }
      setQuantityToMap(aux);
    } else setQuantityToMap(PIN_QUANTITY);
  };

  useEffect(() => {
    if (hasError) {
      errorShake();
      errorText();
    }
  }, [errorShake, errorText, hasError]);

  useEffect(() => {
    handleMakeArr();
  }, [pinQuantity]);
  return (
    <View style={styles.container}>
      <Controller
        control={control}
        name={name}
        render={() => (
          <TextInput
            style={[styles.input]}
            maxLength={quantityToMap.length}
            accessibilityLabel={accessibilityLabelValue}
            keyboardType="decimal-pad"
            ref={inputRef}
            onChangeText={(value) => handleValueChange(value)}
            onBlur={isDesktop ? handleBlur : undefined}
          />
        )}
      />

      <Animated.View style={[styles.content, { transform: [{ translateX: shakeAnimation }] }]}>
        {quantityToMap.map((val, index) => (
          <OTPInputDot
            onPressInput={handleFocus}
            index={index}
            key={val}
            filled={valuesArray[index]}
            isSubmiting={isSubmiting}
            hashedValue={hashedValue}
            hasError={hasError}
            isCompleted={currentValue.length === quantityToMap.length}
            isNext={valuesArray.length === index}
          />
        ))}
      </Animated.View>
      <CustomText
        size="mbig"
        color="error"
        reanimated
        align="center"
        rStyle={[
          styles.error,
          {
            opacity: errorAnimation,
            transform: [{ translateY: translationY }],
          },
        ]}
        animated>
        {hasError && errorMessage}
      </CustomText>
    </View>
  );
};

export default CustomInputPassword;
