import React, { FC } from 'react';
import { SvgProps } from 'react-native-svg';

import GATOChristmasLogo from '@assets/svg/GATOChristmasLogo';
import GATOLogo from '@assets/svg/GATOLogo';
import useFeatureFlag, { christmasEventSelector } from '@store/featureFlag';

const GetLogo: FC<SvgProps> = (props) => {
  // store
  const isChristmas: boolean = useFeatureFlag(christmasEventSelector);

  // render
  if (isChristmas) {
    return <GATOChristmasLogo {...props} />;
  }
  return <GATOLogo {...props} />;
};

export default GetLogo;
