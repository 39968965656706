import { Colors } from '@atoms/CustomText';
import { ImageSourcePropType, ViewStyle } from 'react-native';

export interface GatoBannersProps {
  containerStyle?: ViewStyle | ViewStyle[];
  useCase: GatoBannerUseCase;
}

export enum GatoBannerUseCase {
  CREATOR = 'creator',
  SHARE = 'share'
}

export type GatoBannerThemeTypes = {
  [x in GatoBannerUseCase]: ThemeOptions;
};

export type ThemeOptions = {
  gradientColors: string[];
  button: {
    title: string;
    bgColor: string;
    contrastText: boolean;
  };
  title: string;
  subtitle: string;
  textColor: Colors;
  illustration: ImageSourcePropType;
};
