import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: Colors.black,
    paddingHorizontal: moderateScale(35),
    paddingVertical: moderateScale(40),
    borderWidth: 1,
    width: '100%'
  },
  divider: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: Colors.textInputDefault,
    marginRight: moderateScale(35),
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  tabWrapper: {
    flexDirection: 'row',
    width: '100%',
    position: 'relative'
  },
  body: { marginTop: moderateScale(40) }
});
