/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, memo, useEffect, useState } from 'react';
import { FlatList, Pressable } from 'react-native';
import { StackActions, useNavigation } from '@react-navigation/native';
import { AnimatePresence, View } from 'moti';

import CustomText from '@atoms/CustomText';
import ChevronLeft from '@assets/svg/ChevronLeft';
import { Colors } from '@theme';
import Report from '@assets/svg/report.svg';

import { styles } from './styles';
import { MenuItemProps, PopUpMenuAction, PopUpMenuLink, PopUpMenuProps } from './popUpProps';
import { rootProp } from 'app/navigation/types';

const MenuItem = ({ item, isFirst, isLast, fromResponse }: MenuItemProps) => {
  // props
  const { title, Icon, report } = item as PopUpMenuAction;
  const { route } = item as PopUpMenuLink;

  // states
  const [hover, setHover] = useState(false);
  const [pressing, setPressing] = useState(false);

  // hooks
  const { dispatch } = useNavigation<rootProp>();

  // functions
  function handleNavigation(): void {
    dispatch(StackActions.push(route));
  }

  // constants
  const pressableEvents = {
    onHoverIn: () => setHover(true),
    onHoverOut: () => setHover(false),
    onPressIn: () => setPressing(true),
    onPressOut: () => setPressing(false)
  };

  const isActive = hover || pressing;

  // render
  if ('action' in item) {
    const activeTextColor = !item.danger ? 'limeGreen' : 'error';
    const activeIconColor = !item.danger ? Colors.limeGreen : Colors.inputError;
    return (
      <Pressable
        onPress={item.action}
        style={[styles.item, isFirst && styles.firstItem, isLast && styles.lastItem]}
        {...pressableEvents}>
        {Icon && <Icon style={styles.icon} fill={!fromResponse && isActive ? activeIconColor : undefined} />}
        {report && <img style={styles.icon} src={Report} alt="Report" />}
        <CustomText
          size="lmedium"
          weight="interSemi"
          customStyle={styles.title}
          align="left"
          color={fromResponse ? 'error' : isActive ? activeTextColor : undefined}>
          {title}
        </CustomText>
        {!item.danger && (
          <ChevronLeft style={styles.chevron} fill={isActive ? Colors.limeGreen : undefined} />
        )}
      </Pressable>
    );
  }

  return (
    <Pressable
      {...pressableEvents}
      onPress={handleNavigation}
      style={[styles.item, isFirst && styles.firstItem, isLast && styles.lastItem]}>
      {Icon && <Icon style={styles.icon} fill={isActive ? Colors.limeGreen : undefined} />}
      <CustomText
        size="lmedium"
        weight="interSemi"
        customStyle={styles.title}
        align="left"
        color={isActive ? 'limeGreen' : undefined}>
        {title}
      </CustomText>
      <ChevronLeft style={styles.chevron} fill={isActive ? Colors.limeGreen : undefined} />
    </Pressable>
  );
};

const PopUpMenu: FC<PopUpMenuProps> = ({
  items,
  visible,
  onDismiss,
  style,
  fromResponse,
  disabled = false
}) => {
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (visible && onDismiss) {
      timer = setTimeout(() => {
        onDismiss();
      }, 4000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [visible]);

  return (
    <AnimatePresence>
      {visible && (
        <View
          key="menu"
          style={styles.modalVisible}
          from={{
            opacity: 1
          }}
          exit={{
            opacity: 0
          }}>
          <Pressable
            disabled={disabled}
            onHoverOut={(e) => e.currentTarget && onDismiss && onDismiss()}
            style={[styles.container, style]}>
            <FlatList
              data={items}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item.title}
              renderItem={(item) => (
                <MenuItem
                  item={item.item}
                  isFirst={item.index === 0}
                  isLast={item.index === items.length - 1}
                  fromResponse={fromResponse}
                />
              )}
            />
          </Pressable>
        </View>
      )}
    </AnimatePresence>
  );
};

export default memo(PopUpMenu);
