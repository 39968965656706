import { Colors } from '@theme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.black
  },
  cardsWrapper: { flexDirection: 'row', justifyContent: 'space-between' },
  select: {
    marginLeft: 20,
    zIndex: 999
  }
});
