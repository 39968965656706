import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@theme';
import { moderateScale, verticalScale } from '@utils/scaling';
import { isDesktop } from '@constants/platform';

export default StyleSheet.create({
  container: {
    width: '100%'
  },
  inputContainer: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.greyFour,
    backgroundColor: Colors.blackTwo,
    paddingHorizontal: moderateScale(12),
    paddingVertical: moderateScale(15.5),
    flexDirection: 'row',
    alignItems: 'center'
  },
  inputSearchWithText: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  secureInputContainer: {
    paddingRight: 50
  },
  noPadding: {
    padding: 0
  },
  hide: {
    display: 'none'
  },
  show: {
    display: 'flex',
    marginBottom: verticalScale(16)
  },
  noMarginBottom: {
    marginBottom: 0
  },
  padding: {
    paddingVertical: verticalScale(12)
  },
  label: {
    marginTop: verticalScale(7),
    lineHeight: verticalScale(19),
    position: 'absolute',
    left: verticalScale(16)
  },
  input: {
    fontSize: Fonts.SIZES.lMedium,
    color: Colors.white,
    fontFamily: Fonts.INTER_MEDIUM,
    marginLeft: moderateScale(12),
    flex: 1,
    outlineStyle: 'none'
  },
  error: {
    paddingLeft: 6
  },
  downArrowCurrency: {
    position: 'absolute',
    top: verticalScale(18),
    right: verticalScale(15)
  },
  passwordCTA: {
    position: 'absolute',
    top: 0,
    right: isDesktop ? 0 : 15,
    height: '100%',
    width: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorMessage: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  focusText: {
    marginTop: moderateScale(8),
    marginBottom: isDesktop ? moderateScale(8) : 0
  },
  rigthIcon: {
    marginVertical: -5
  },
  skeleton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    borderRadius: 8
  },
  results: {
    zIndex: 99,
    flex: 1,
    width: '100%',
    position: 'absolute',
    marginTop: moderateScale(50),
    borderTopColor: Colors.greyFour,
    borderTopWidth: moderateScale(1),
    backgroundColor: Colors.socialBackground,
    borderBottomRightRadius: moderateScale(16),
    borderBottomLeftRadius: moderateScale(16),
    // paddingHorizontal: moderateScale(20),
    paddingTop: moderateScale(10),
    paddingBottom: moderateScale(8)
  },
  emptyResultsText: {
    paddingVertical: moderateScale(4),
    paddingHorizontal: moderateScale(20)
  },
  resultContainer: {
    minHeight: moderateScale(56),
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: moderateScale(9),
    paddingHorizontal: moderateScale(20),
    overflow: 'hidden'
  },
  resultTitle: {
    marginBottom: moderateScale(4),
    marginRight: moderateScale(50),
    maxWidth: '40ch'
  },
  avatar: {
    width: moderateScale(34),
    height: moderateScale(34),
    borderRadius: moderateScale(34 / 2),
    marginTop: 0
  },
  texts: {
    marginLeft: moderateScale(12),
    flexDirection: 'column'
  },
  viewAllResults: {
    paddingHorizontal: moderateScale(20),
    paddingVertical: moderateScale(12),
    textDecorationLine: 'underline'
  },
  highlight: {
    color: Colors.error
  },
  onHover: {
    backgroundColor: `${Colors.grey}30`
  },
  verified: {
    position: 'absolute',
    top: -3,
    right: -3,
    transform: [{ scale: 1.2 }],
    zIndex: 3
  }
});
