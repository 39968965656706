import { moderateScale } from '@utils/scaling';
import { StyleSheet } from 'react-native';
import { Colors } from '@theme';

export const styles = (isActive: boolean) =>
  StyleSheet.create({
    tab: {
      paddingBottom: moderateScale(16),
      paddingHorizontal: moderateScale(16),
      paddingTop: moderateScale(4),
      borderBottomWidth: 1,
      borderBottomColor: isActive ? Colors.limeGreen : Colors.textInputDefault,
      marginTop: 3,
      zIndex: 99
    }
  });
