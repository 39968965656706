import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Pressable, View } from 'react-native';
import { Portal } from '@gorhom/portal';

import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { FooterButton } from '@atoms/FooterButton';
import { useDebounce } from '@hooks/useDebounce';
import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import { useEditUserEmail } from '@services/useEditUserEmail';
import { useValidateEmail } from '@services/useValidateEmail';
import { authTokenSelector, useStore } from '@store/index';
import toast from '@utils/toast';
import { emailRegex, passwordRegex } from '@utils/validations';
import { RootStackNavigator } from 'app/navigation/types';
import { CustomModal } from '@molecules/CustomModal';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import EmailIcon from '@assets/svg/EmailIcon';
import { useUserData } from '@services/useUserData';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';

import { styles } from '../styles';
import { ScreenTitle } from '..';

type ChangeEmailProps = NativeStackScreenProps<RootStackNavigator, 'ChangeEmail'>;

interface ChangeEmailParams {
  newEmail: string;
  password: string;
}

const ChangeEmail = ({ navigation }: ChangeEmailProps) => {
  const authToken = useStore(authTokenSelector);
  const { data: userData, isLoading: isLoadingData } = useUserData(authToken || '');

  const [emailAvailable, setEmailAvailable] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const i18n = useTranslations();
  const updateEmail = useEditUserEmail(authToken || '');
  const validEmail = useValidateEmail();

  const cancelChangeEmail = () => {
    setModalOpen(false);
  };

  const {
    control,
    getValues,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit
  } = useForm<ChangeEmailParams>({ mode: 'onChange' });

  const newEmail = watch('newEmail');

  const debouncedEmail = useDebounce(newEmail, 1000);

  const isDisabled = newEmail === userData?.email || !newEmail || Boolean(errors.newEmail?.message);

  function handleGoBack(): void {
    if (isWebResponsive) {
      navigation.goBack();
      return;
    }
    navigation.replace('AccountSettings');
  }

  const onSubmit = (values: ChangeEmailParams) => {
    const dataToSend = {
      newEmail: values.newEmail,
      password: values.password
    };
    updateEmail.mutate(dataToSend, {
      onSuccess: () => {
        navigation.navigate('ValidateCode', {
          userEmail: dataToSend.newEmail,
          password: dataToSend.password,
          type: 'EMAIL',
          userId: 1
        });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data?.message;
          if (message === 'Incorrect Password.') {
            setError('password', { message });
          } else {
            toast.danger({ icon: 'error', title: i18n.t('account_settings.changes_not_saved') });
            handleGoBack();
          }
        }
      }
    });
    setModalOpen(false);
  };

  const handleChangeEmail = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    setEmailAvailable(false);
    if (debouncedEmail && !errors.newEmail?.message) {
      validEmail.mutate(debouncedEmail, {
        onSuccess: () => {
          clearErrors('newEmail');
          setEmailAvailable(true);
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = i18n.t(error?.response?.data.message);
            setError('newEmail', { message });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail]);

  return (
    <>
      <Layout
        padding={isMobile}
        hasBottomCTAS={
          <FooterButton>
            <Button
              disabled={isDisabled}
              customStyle={styles.footerButton}
              customTextStyle={styles.footerButtonText}
              onPress={handleChangeEmail}>
              {i18n.t('account_settings.continue')}
            </Button>
          </FooterButton>
        }
        verticallyCentered={isDesktop}>
        <View style={styles.screenContainer}>
          <View style={styles.screenInnerContainer}>
            {isMobile || isWebResponsive ? (
              <View style={styles.goBack}>
                <GoBackButton goBackAction={handleGoBack} />
              </View>
            ) : null}
            <ScreenTitle>{i18n.t('account_settings.change_email')}</ScreenTitle>
            <View style={styles.formBody}>
              <Field
                editable={false}
                error={errors}
                icon={EmailIcon}
                name="oldEmail"
                control={control}
                getValues={getValues}
                defaultValue={userData?.email}
                loading={isLoadingData}
              />
              <Field
                error={errors}
                icon={EmailIcon}
                placeholder={i18n.t('account_settings.new_email_placeholder').toString()}
                focusText={i18n.t('account_settings.new_email_focus_text').toString()}
                name="newEmail"
                control={control}
                getValues={getValues}
                isAvailable={emailAvailable}
                rules={{
                  required: i18n.t('register.required').toString(),
                  pattern: {
                    value: emailRegex,
                    message: i18n.t('register.email_pattern_error')
                  }
                }}
                type="email-address"
                loading={isLoadingData}
              />
              {isDesktop && !isWebResponsive ? (
                <Button
                  disabled={isDisabled}
                  customStyle={styles.footerButton}
                  customTextStyle={styles.footerButtonText}
                  onPress={handleChangeEmail}>
                  {i18n.t('account_settings.continue')}
                </Button>
              ) : null}
            </View>
          </View>
        </View>
      </Layout>
      <Portal>
        <CustomModal
          visible={modalOpen}
          onOverlayPress={cancelChangeEmail}
          bottomFixed={false}
          customStyle={undefined}>
          <>
            <CustomText weight="interSemi" size="xmedium" customStyle={styles.modalTitle}>
              {i18n.t('account_settings.new_email_confirm_message')}
            </CustomText>
            <Field
              icon={PasswordInputIcon}
              containerStyles={styles.passInput}
              name="password"
              control={control}
              placeholder={i18n.t('register.password_placeholder').toString()}
              rules={{
                pattern: {
                  value: passwordRegex,
                  message: i18n.t('register.password_min_lenght_error')
                }
              }}
              secure
              focusText={i18n.t('register.password_text').toString()}
              getValues={getValues}
              error={errors}
            />
            <View style={styles.buttons}>
              <Pressable style={[styles.button, styles.cancelModal]} onPress={cancelChangeEmail}>
                <CustomText weight="interSemi">{i18n.t('account_settings.new_email_cancel_text')}</CustomText>
              </Pressable>
              <Button
                loading={updateEmail.isLoading}
                customStyle={styles.button}
                onPress={handleSubmit(onSubmit)}
                customTextStyle={styles.confirmButton}>
                {i18n.t('account_settings.new_email_confirm_text')}
              </Button>
            </View>
          </>
        </CustomModal>
      </Portal>
    </>
  );
};

export default ChangeEmail;
