import React, { LegacyRef, ReactNode, useCallback } from 'react';
import { View, KeyboardAvoidingView, ScrollView, Platform, ScrollViewProps } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withTiming } from 'react-native-reanimated';
import { useFocusEffect } from '@react-navigation/core';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { LinearGradient } from 'expo-linear-gradient';

import { Spacing, Colors } from '@theme';
import CustomText from '@atoms/CustomText';
import { Navbar } from '@molecules/Navbar';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import DesktopFooter from '@molecules/DesktopFooter';

import { styles } from './styles';

interface Props {
  children: React.ReactNode;
  hasBottomCTAS?: React.ReactElement;
  headerLeft?: React.ReactElement;
  title?: React.ReactElement;
  headerRight?: React.ReactElement;
  bounces?: boolean;
  scrollEnabled?: boolean;
  tabOffset?: number;
  withScroll?: boolean;
  showScrollIndicator?: boolean;
  padding?: boolean;
  header?: string;
  backgroundColor?: string;
  verticallyCentered?: boolean;
  paddingTop?: number | string;
  desktopFooter?: boolean;
  withGradient?: boolean;
  customRef?: LegacyRef<ScrollView> | undefined;
}

const AnimatedKeyboardAvoidingView = isMobile
  ? Animated.createAnimatedComponent(KeyboardAvoidingView)
  : Animated.View;

export const Layout = ({
  children,
  hasBottomCTAS,
  bounces = true,
  scrollEnabled = true,
  tabOffset,
  title,
  headerRight,
  headerLeft,
  showScrollIndicator = true,
  withScroll = true,
  padding = true,
  header,
  backgroundColor = isMobile ? Colors.darkGrey : Colors.black,
  verticallyCentered = false,
  paddingTop,
  desktopFooter = false,
  withGradient,
  customRef: ref,
  ...props
}: Props & ScrollViewProps) => {
  const insets = useSafeAreaInsets();
  const opacity = useSharedValue(0);

  useFocusEffect(
    useCallback(() => {
      opacity.value = withDelay(300, withTiming(1));
      return () => {
        opacity.value = withTiming(0);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  const rStyle = useAnimatedStyle(() => ({ opacity: opacity.value }), []);
  const verticalAlignment = verticallyCentered ? styles.verticallyCentered : {};

  const backgroundGleam = (
    <>
      <View style={styles.gleam1} />
      <View style={styles.gleam2} />
    </>
  );

  const innerContainerPadding = Spacing.LATERALBIG;
  const desktopBottomPadding = !padding || desktopFooter ? 0 : Spacing.LATERALLARGE;
  const mobileBottomPadding = tabOffset || undefined;
  const innerContainerBottomPadding = isMobile ? mobileBottomPadding : desktopBottomPadding;

  const WithScroll = withScroll ? (
    <ScrollView
      ref={ref}
      nestedScrollEnabled
      showsVerticalScrollIndicator={showScrollIndicator}
      scrollEnabled={scrollEnabled}
      bounces={bounces}
      contentContainerStyle={[
        {
          paddingBottom: innerContainerBottomPadding,
        },
        padding && isDesktop && !isWebResponsive ? { paddingTop: Spacing.LARGE } : null,
        paddingTop !== undefined && { paddingTop },
        verticalAlignment,
      ]}
      style={[styles.inner]}
      {...props}>
      {!!header && (
        <CustomText
          customStyle={[styles.mb20, { paddingHorizontal: !padding ? innerContainerPadding : undefined }]}
          size="big"
          color="black"
          align="left"
          weight="interSemi">
          {header}
        </CustomText>
      )}
      {isMobile ? (
        children
      ) : (
        <View
          style={[
            padding && styles.horizontallyCentered,
            padding && desktopFooter && { paddingBottom: Spacing.XLARGE },
            styles.inner,
          ]}>
          {children}
        </View>
      )}
      {isDesktop && desktopFooter && <DesktopFooter />}
    </ScrollView>
  ) : (
    <View
      style={[
        styles.inner,
        {
          padding: padding ? innerContainerPadding : undefined,
          paddingBottom: innerContainerBottomPadding,
        },
        padding && isDesktop ? { paddingTop: Spacing.LARGE } : undefined,
        verticalAlignment,
      ]}>
      {!!header && (
        <CustomText
          customStyle={[styles.mb20, { paddingHorizontal: !padding ? innerContainerPadding : undefined }]}
          size="mbig"
          color="black"
          align="left"
          weight="interSemi">
          {header}
        </CustomText>
      )}
      {children}
      {isDesktop && desktopFooter && (
        <View style={padding && { marginHorizontal: -innerContainerPadding }}>
          <DesktopFooter />
        </View>
      )}
    </View>
  );

  const insetsConfig = {
    paddingTop: insets.top,
    paddingRight: insets.right,
    paddingBottom: insets.bottom,
    paddingLeft: insets.left,
  };

  const getWrapper = (body: ReactNode): JSX.Element => {
    if (withGradient) {
      return (
        <LinearGradient
          colors={['#212229', '#0C0C0F', '#1D1824']}
          style={[styles.container, insetsConfig]}
          start={{ x: 0.5, y: 0 }}>
          {body}
        </LinearGradient>
      );
    }
    return (
      <View
        style={[
          styles.container,
          insetsConfig,
          {
            backgroundColor,
          },
        ]}>
        {body}
      </View>
    );
  };
  const enabledFooter = isMobile || isWebResponsive;
  return getWrapper(
    <>
      {/* {withGradient && <ImageBackground source={GradientBackground} style={styles.gradient} />} */}
      {(title || headerLeft || headerRight) && <Navbar title={title} left={headerLeft} right={headerRight} />}
      <AnimatedKeyboardAvoidingView
        style={[styles.avoiding, rStyle, { backgroundColor }]}
        behavior="padding"
        enabled={Platform.OS === 'ios'}>
        {WithScroll}
        {hasBottomCTAS && enabledFooter ? (
          <View style={styles.btn}>
            <KeyboardAvoidingView enabled behavior="padding">
              {hasBottomCTAS}
            </KeyboardAvoidingView>
          </View>
        ) : null}
        {isDesktop ? backgroundGleam : null}
      </AnimatedKeyboardAvoidingView>
    </>
  );
};
