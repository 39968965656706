import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 25,
    backgroundColor: Colors.darkGrey,
    borderRadius: 16,
    paddingVertical: moderateScale(40),
    paddingHorizontal: moderateScale(64),
    width: '48%',
    minWidth: moderateScale(405),
    height: moderateScale(256),
    justifyContent: 'space-between'
  },
  flexWrapper: { flexDirection: 'row', alignItems: 'center' },
  emptyHistoryContainer: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.disabled,
    width: '100%',
    minHeight: moderateScale(110),
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  historyContainer: {
    width: '100%',
    minHeight: moderateScale(110)
  },
  image: {
    width: moderateScale(48),
    height: moderateScale(48),
    alignSelf: 'center'
  }
});
