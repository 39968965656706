import React from 'react';
import GoogleAdManagerBanner, { GoogleAdManagerBannerProps } from './GoogleAdManagerBanner.web';
import Skeleton from '@atoms/Skeleton';
import { useEffect, useState } from 'react';
import { unitsIdData } from './constants';

const GoogleAdManagerBannerWithDelay = ({
  unitId,
  adErrorPlaceholder
}: GoogleAdManagerBannerProps): JSX.Element | null => {
  // This delay is implemented because when the component is rendered, the ad is not loaded. I think this could be because the ad is not available yet on google because it seems to be loaded in the component that was unmount.
  const [delay, setDelay] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setDelay(false);
    }, 1000); // Working with low fail rate with 3000ms. Keep 1000ms for now.
  }, []);
  if (!adErrorPlaceholder || !unitId) {
    return null;
  }
  return (
    <>
      {delay ? (
        <Skeleton width={unitsIdData[unitId].size.width} height={unitsIdData[unitId].size.height} />
      ) : (
        <GoogleAdManagerBanner unitId={unitId} adErrorPlaceholder={adErrorPlaceholder} />
      )}
    </>
  );
};

export default GoogleAdManagerBannerWithDelay;
