export function numberToCurrency(amount: number): string {
  return amount
    .toLocaleString('es-AR', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
      currencyDisplay: 'symbol'
    })
    .replace('US', '');
}
