import { AdblockCat } from '@assets/svg/AdblockCat.web';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { CustomModal } from '@molecules/CustomModal';
import React from 'react';
import { View, Pressable, Platform } from 'react-native';
import RNRestart from 'react-native-restart';
import styles from './styles';

export const AdblockModal = (): JSX.Element => {
  //hooks
  const i18n = useTranslations();
  //functions
  function handlePress(): void {
    if (Platform.OS !== 'web') {
      return RNRestart.Restart();
    }
    return window?.location?.reload();
  }

  //render
  return (
    <CustomModal visible customStyle={styles.modal}>
      <View>
        <View style={styles.cat}>
          <AdblockCat />
        </View>
        <View style={styles.body}>
          <CustomText align="center" weight="interSemi" size="xmedium" customStyle={styles.title}>
            {i18n.t('adblock_detect.title')}
          </CustomText>
          <View>
            <CustomText align="center" weight="interRegular" size="medium" customStyle={styles.bodyText}>
              {i18n.t('adblock_detect.message')}
            </CustomText>
            <CustomText align="center" weight="interRegular" size="medium" customStyle={styles.bodyText}>
              {i18n.t('adblock_detect.body')}
            </CustomText>
          </View>
        </View>
        <View style={styles.buttonWrapper}>
          <Pressable style={styles.cancelBtn}>
            <CustomText weight="interSemi" color="white" size="medium">
              {i18n.t('adblock_detect.decline_btn')}
            </CustomText>
          </Pressable>
          <Button customStyle={styles.reloadButton} onPress={handlePress} customTextStyle={styles.reloadText}>
            {i18n.t('adblock_detect.accept_btn')}
          </Button>
        </View>
      </View>
    </CustomModal>
  );
};

interface IAdblockModalProps {
  closeModal(): void;
  open: boolean;
}
