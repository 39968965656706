import React, { FC } from 'react';
import { Colors } from '@theme';
import { View } from 'react-native';
import { styles } from './styles';
import { IconBadgeProps } from './types';

export const IconBadge: FC<IconBadgeProps> = ({
  icon,
  bgColor = Colors.surfaceBrandHover,
  borderRadius = 100,
  customStyle
}): JSX.Element => {
  //render
  return (
    <View
      style={[
        {
          borderRadius: borderRadius,
          backgroundColor: bgColor
        },
        styles.container,
        customStyle
      ]}>
      {icon}
    </View>
  );
};
