// Para cuando el user cierra la pestaña
import { useEffect } from 'react';

const useNavigatorBackButton = (handleBeforeBackButton: any) => {
  useEffect(() => {
    window.addEventListener('popstate', handleBeforeBackButton);
    return () => {
      window.removeEventListener('popstate', handleBeforeBackButton);
    };
  }, []);
};

export default useNavigatorBackButton;
