import React, { memo, useMemo, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';
import { CommonActions, useNavigation } from '@react-navigation/core';

import { Colors } from '@theme';
import CustomText from '@atoms/CustomText';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { capitalizeFirstLetters } from '@utils/common';
import TimeAgo from '@atoms/TimeAgo';
import Verified from '@assets/svg/Verified';
import CommentMenu from '@assets/svg/CommentMenu';
import Trash from '@assets/svg/Trash';
import { LikeCommentCTA } from '@molecules/LikeCommentCTA';
import Report from '@assets/svg/Report';
import PopUpMenu from '@molecules/PopUpMenu';
import ItemDivider from '@atoms/ItemDivider';

import useReportMenu from '../useReportMenu';

import { styles } from './styles';
import { useTranslations } from '@hooks/useTranslation';

const Response = ({ commentResponse, lastItem, userId }: any) => {
  const { response, user } = commentResponse;
  const navigation = useNavigation();
  const i18n = useTranslations();

  const [showMenu, setShowMenu] = useState(false);

  const { handleDeleteCommentMenu, handleReportCommentMenu, deleteResponseComment } = useReportMenu(
    response?.id,
    true
  );

  const showCommentMenu = useMemo(() => userId === user?.id, [user?.id, userId]);

  const deleteItems = [
    { title: i18n.t('comments.delete_comment'), action: handleDeleteCommentMenu, Icon: Trash, danger: true }
  ];
  const reportItems = [
    { title: i18n.t('comments.report_comment'), action: handleReportCommentMenu, danger: true, report: true }
  ];

  const handleUser = async () => {
    const { userName } = user;
    const navigateAction = CommonActions.navigate({
      name: 'UserProfile',
      params: {
        userName
      },
      key: `UserProfile-${userName}`
    });
    navigation.dispatch(navigateAction);
  };

  return deleteResponseComment.isLoading ? (
    <View style={styles.loading}>
      <ActivityIndicator size="large" color={Colors.greySix} />
    </View>
  ) : (
    <View style={styles.container}>
      <PopUpMenu
        items={showCommentMenu ? deleteItems : reportItems}
        visible={showMenu}
        style={styles.popupMenu}
        onDismiss={() => setShowMenu(false)}
        fromResponse
      />
      <Pressable style={styles.user} onPress={handleUser}>
        <AvatarProfile
          customContainerStyle={styles.avatar}
          imageUrl={user.profileImage ?? undefined}
          backgroundUrl={user.backgroundProfileImage ?? undefined}
          offsetKitten={5}
        />
        <View style={styles.info}>
          <View style={styles.header}>
            <View style={styles.name}>
              <CustomText
                weight="interSemi"
                align="left"
                size="medium"
                color="white"
                customStyle={{ maxWidth: '25ch' }}
                numberOfLines={1}>
                {capitalizeFirstLetters(user?.fullName)}
              </CustomText>

              {user?.creator ? <Verified style={styles.creator} /> : null}
            </View>

            <Pressable style={styles.menu} onPress={() => setShowMenu((prev) => !prev)}>
              <CommentMenu />
            </Pressable>
          </View>
          <TimeAgo date={response?.created} />
        </View>
      </Pressable>
      <View style={styles.comment}>
        <CustomText weight="interSemi" align="left" size="medium" color="whiteTwo">
          {response?.description}
        </CustomText>
      </View>
      <View style={styles.actions}>
        <LikeCommentCTA
          likeByYou={commentResponse?.likeByYou}
          likesCount={commentResponse?.likesCount || 0}
          commentId={response?.id}
          isResponse
        />
      </View>

      {!lastItem && <ItemDivider />}
    </View>
  );
};

export default memo(Response);
