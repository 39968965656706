import { GAMTags, RatiosUpperLimits } from '@interfaces/gam';

export const ratiosUpperLimits: RatiosUpperLimits = {
  '9:16': 1, // From 0 to 1
  '4:3': 1.55555556, // From 1 to 1.55555556
  '16:9': 100, // From 1.55555556 to 100
};

export const gamTags: GAMTags = {
  '9:16':
    'https://pubads.g.doubleclick.net/gampad/ads?iu=/23031154824/ca-video-pub-3402581483331773-tag&description_url=https%3A%2F%2Fgato.us&tfcd=0&npa=0&sz=720x1280%7C720x1280&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=',
  '4:3':
    'https://pubads.g.doubleclick.net/gampad/ads?iu=/23031154824/ca-video-pub-3402581483331773-tag&description_url=https%3A%2F%2Fgato.us&tfcd=0&npa=0&sz=640x480%7C640x480&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=',
  '16:9':
    'https://pubads.g.doubleclick.net/gampad/ads?iu=/23031154824/ca-video-pub-3402581483331773-tag&description_url=https%3A%2F%2Fgato.us&tfcd=0&npa=0&sz=1920x1080%7C1920x1080&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=',
};

// 16:9 mapped to 2 sizes, 640x360 and 1920x1080, I dont know if this name is the best
export const mobileGamTags: GAMTags = {
  '16:9':
    'https://pubads.g.doubleclick.net/gampad/ads?iu=/23031154824/ca-video-pub-3402581483331773-tag&description_url=https%3A%2F%2Fgato.us&tfcd=0&npa=0&sz=640x360%7C640x360&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=',
  '4:3': '',
  '9:16': '',
};
