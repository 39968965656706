import React, { useEffect, useRef, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormWatch } from 'react-hook-form';
import {
  ActivityIndicator,
  Pressable,
  StyleProp,
  TextInput,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { SvgProps } from 'react-native-svg';
import { CommonActions, useNavigation } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from '@hooks/useOnClickOutside';
import EyeClosed from '@assets/svg/EyeClosed';
import ErrorMessage from '@atoms/ErrorMessage';
import { isIos } from '@constants/platform';
import { Colors } from '@theme';
import { animateLayout } from '@utils/animation';
import EyeOpened from '@assets/svg/EyeOpened';
import CustomText from '@atoms/CustomText';
import AvailableIcon from '@assets/svg/AvailableIcon';
import Skeleton from '@atoms/Skeleton';
import CloseIcon from '@assets/svg/CloseIcon';
import { AvatarProfile } from '@atoms/AvatarProfile';
import VerifiedIcon from '@assets/svg/VerifiedIcon';

import styles from './styles';

interface Props extends TextInputProps {
  name: string;
  icon?: (props: SvgProps) => JSX.Element;
  error: FieldErrors<any>;
  focusText?: string;
  control: Control<any, object>;
  getValues: UseFormGetValues<any>;
  rules?: React.ComponentProps<typeof Controller>['rules'];
  onPress?: () => void;
  customStyle?: any;
  onFocus?: () => void;
  defaultValue?: React.ComponentProps<typeof TextInput>['defaultValue'];
  editable?: React.ComponentProps<typeof TextInput>['editable'];
  secure?: React.ComponentProps<typeof TextInput>['secureTextEntry'];
  autoFocus?: React.ComponentProps<typeof TextInput>['autoFocus'];
  type?: React.ComponentProps<typeof TextInput>['keyboardType'];
  testID?: React.ComponentProps<typeof TextInput>['testID'];
  textContentType?: React.ComponentProps<typeof TextInput>['textContentType'];
  accessibilityLabelValue?: React.ComponentProps<typeof TextInput>['accessibilityLabel'];
  maxLength?: React.ComponentProps<typeof TextInput>['maxLength'];
  labelWithoutEdit?: boolean;
  placeholder?: string;
  hide?: boolean;
  isAvailable?: boolean;
  loading?: boolean;
  containerStyles?: StyleProp<ViewStyle> | undefined;
  multiline?: boolean;
  withoutErrorText?: boolean;
  autoCapitalize?: React.ComponentProps<typeof TextInput>['autoCapitalize'];
  fromComments?: boolean;
  handleFocus?: (val: boolean) => void;
  errorStyle?: StyleProp<ViewStyle> | undefined;
  removeMarginBottom?: boolean;
  isSearchInput?: boolean;
  watch?: UseFormWatch<any>;
  clearField?: () => void;
  searchResults?: Array<any>;
  hasMoreItems?: boolean;
  searchIsLoading?: boolean;
  hasSearchResults?: boolean;
  customRef?: any;
}

const Field = ({
  name,
  error,
  control,
  defaultValue = '',
  getValues,
  focusText,
  rules,
  editable = true,
  onPress,
  onFocus,
  secure,
  customStyle,
  autoFocus,
  type,
  testID = '',
  textContentType = 'none',
  accessibilityLabelValue = '',
  maxLength,
  placeholder,
  hide,
  containerStyles,
  isAvailable,
  icon,
  loading,
  multiline,
  withoutErrorText,
  autoCapitalize = 'none',
  fromComments,
  handleFocus,
  errorStyle,
  removeMarginBottom,
  isSearchInput,
  watch,
  clearField,
  searchResults,
  hasMoreItems,
  searchIsLoading,
  hasSearchResults,
  customRef,
  ...props
}: Props) => {
  const [hidePassword, setHidePassword] = useState(true);
  const borderAnimation = useSharedValue(0);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<TextInput>(null);
  const ref = useRef(null);
  const [showSelect, setShowSelect] = useState(true);
  const i18n = useTranslation();

  useOnClickOutside(ref, () => setShowSelect(false));

  const [isHovered, setIsHovered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleMouseEnter = (index: any) => {
    setCurrentIndex(index);
    setIsHovered(true);
  };
  const handleMouseLeave = (index: any) => {
    setCurrentIndex(index);
    setIsHovered(false);
  };

  const navigation = useNavigation();

  const Icon = icon;

  const isEmpty = !getValues(name);
  const hasValue = watch && watch(name);

  const errorMsg = error[name]?.message;

  const setFocusHandler = (val: boolean) => {
    if (handleFocus) handleFocus(val);
    setFocus(val);
  };

  const iconColor = () => {
    if (errorMsg) {
      return Colors.inputError;
    }
    if (focus) {
      return Colors.limeGreen;
    }
    return Colors.greySix;
  };

  useEffect(() => {
    if (focus) {
      borderAnimation.value = withTiming(1);
    } else {
      borderAnimation.value = withTiming(0);
    }
    if (errorMsg) {
      borderAnimation.value = withTiming(2);
    }
  }, [focus, borderAnimation, errorMsg]);

  const borderInputStyle = useAnimatedStyle(
    () => ({
      borderColor: interpolateColor(
        borderAnimation.value,
        [0, 1, 2, 3],
        [Colors.transparentBlack, Colors.greyFour, Colors.inputError, Colors.purple]
      ),
    }),
    []
  );

  const borderCommentStyle = useAnimatedStyle(
    () => ({
      borderColor: interpolateColor(
        borderAnimation.value,
        [0, 1, 2],
        [Colors.transparentBlack, Colors.purple, Colors.inputError]
      ),
    }),
    []
  );

  const handlePress = () => {
    if (editable) inputRef.current?.focus();
    if (onPress) onPress();
  };

  if (errorMsg) {
    animateLayout();
  }

  // const highlightSearch = (text, search) => {
  //   // const highlightedText = text.replace(/${search}/gi, (match, index) => (
  //   //   <TextInput key={index} style={styles.highlight}>
  //   //     {match}
  //   //   </TextInput>
  //   // ));

  //   // return <TextInput>{highlightedText}</TextInput>;
  //   const highlightedText = text.replace(/torre/gi, (match, index) => {
  //     console.log('match: ', match);
  //   });

  //   console.log('highlightedText: ', highlightedText);

  //   return text;
  // };

  const skipPressableRef = useRef(null);
  const skipPressableIconRef = useRef(null);

  useEffect(() => {
    skipPressableRef.current.tabIndex = -1;
    if (secure) skipPressableIconRef.current.tabIndex = -1;
  }, [secure]);

  return (
    <View style={[hide ? styles.hide : styles.show, removeMarginBottom && styles.noMarginBottom]}>
      <>
        <View style={styles.container}>
          <Pressable onPress={handlePress} disabled={!editable} ref={skipPressableRef}>
            <Animated.View
              style={[
                styles.inputContainer,
                isSearchInput &&
                  searchResults &&
                  searchResults.length > 0 &&
                  showSelect &&
                  styles.inputSearchWithText,
                fromComments ? borderCommentStyle : borderInputStyle,
                containerStyles,
                fromComments && { borderWidth: 2 },
                secure && styles.secureInputContainer,
              ]}
              pointerEvents={onPress ? 'none' : 'auto'}>
              <>
                {Icon && <Icon opacity={editable === false ? 0.5 : 1} fill={iconColor()} tabIndex={-1} />}

                {!loading && (
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        accessibilityLabel={accessibilityLabelValue}
                        onBlur={() => {
                          onBlur();
                          setFocusHandler(false);
                        }}
                        onChangeText={(text) => {
                          setShowSelect(true);
                          onChange(text);
                        }}
                        value={value}
                        style={[styles.input, customStyle, editable === false && { color: Colors.greySix }]}
                        onFocus={() => {
                          setShowSelect(true);
                          setFocusHandler(true);
                          animateLayout();
                          if (onFocus) {
                            onFocus();
                          }
                        }}
                        selectionColor={Colors.limeGreen}
                        editable={editable}
                        secureTextEntry={secure && hidePassword}
                        autoFocus={autoFocus && isIos}
                        keyboardType={type || 'default'}
                        textContentType={textContentType || 'none'}
                        autoCapitalize={autoCapitalize}
                        testID={testID}
                        ref={customRef ?? inputRef}
                        placeholder={placeholder}
                        placeholderTextColor={error[name]?.message ? Colors.inputError : Colors.textInputGrey}
                        maxLength={maxLength}
                        multiline={multiline}
                        {...props}
                        tabIndex={-1}
                      />
                    )}
                    name={name}
                    rules={rules}
                    defaultValue={defaultValue}
                  />
                )}
                {isAvailable && !error[name]?.message && !isEmpty ? (
                  <AvailableIcon style={styles.rigthIcon} />
                ) : null}
                {loading && <Skeleton customStyle={styles.skeleton} />}
                {isSearchInput && hasValue && !searchIsLoading && (
                  <Pressable onPress={() => clearField && clearField()} hitSlop={20}>
                    <CloseIcon />
                  </Pressable>
                )}
                {searchIsLoading && <ActivityIndicator size="small" />}
              </>
            </Animated.View>
          </Pressable>
          {secure && (
            <Pressable
              hitSlop={10}
              style={({ pressed }) => [styles.passwordCTA, { opacity: pressed ? 0.5 : 1 }]}
              disabled={!editable}
              onPress={() => setHidePassword((prev) => !prev)}
              ref={skipPressableIconRef}>
              <View>
                {hidePassword ? (
                  <EyeClosed
                    width={30}
                    height={24}
                    color={error[name]?.message ? Colors.inputError : Colors.greySix}
                    style={styles.rigthIcon}
                  />
                ) : (
                  <EyeOpened
                    width={30}
                    height={24}
                    color={error[name]?.message ? Colors.inputError : Colors.greySix}
                    style={styles.rigthIcon}
                  />
                )}
              </View>
            </Pressable>
          )}
        </View>
        {focusText && focus && !error[name]?.message && (
          <CustomText
            customStyle={styles.focusText}
            align="left"
            color="greySix"
            weight="interSemi"
            size="msmall">
            {focusText}
          </CustomText>
        )}
        {error[name]?.message && !withoutErrorText && (
          <ErrorMessage text={error[name]?.message?.toString() || ''} style={errorStyle} />
        )}
        {showSelect && isSearchInput && searchResults && searchResults?.length > 0 && (
          <View style={styles.results} ref={ref}>
            {searchResults.map((item, index) => (
              <Pressable
                key={index}
                onPress={item?.onPress}
                style={[styles.resultContainer, isHovered && currentIndex === index && styles.onHover]}
                onHoverIn={() => handleMouseEnter(index)}
                onHoverOut={() => handleMouseLeave(null)}>
                <View>
                  <AvatarProfile
                    customContainerStyle={styles.avatar}
                    imageUrl={item?.image ?? ''}
                    imageUrlPlaceholder={item?.image}
                    offsetKitten={5}
                  />
                  {item?.type === 'CREATOR' && <VerifiedIcon style={styles.verified} />}
                </View>
                <View style={styles.texts}>
                  <CustomText
                    align="left"
                    color="white"
                    weight="interSemi"
                    size="medium"
                    numberOfLines={1}
                    customStyle={styles.resultTitle}>
                    {item?.title}
                  </CustomText>
                  <CustomText align="left" color="greySix" weight="interSemi" size="msmall">
                    {item?.subtitle}
                  </CustomText>
                </View>
              </Pressable>
            ))}

            {hasMoreItems && (
              <Pressable
                onPress={() => {
                  const search = getValues(name);
                  const navigateAction = CommonActions.navigate({
                    name: 'Search',
                    params: {
                      search,
                    },
                    key: `Search-${search}`,
                  });
                  return navigation.dispatch(navigateAction);
                }}>
                <CustomText
                  align="left"
                  color="purple"
                  weight="interSemi"
                  size="medium"
                  customStyle={styles.viewAllResults}>
                  {i18n.t('search_navbar.view_all_results')}
                </CustomText>
              </Pressable>
            )}
          </View>
        )}

        {isSearchInput && hasValue && !hasSearchResults && (
          <View style={styles.results}>
            <CustomText
              align="left"
              color="white"
              weight="interSemi"
              size="medium"
              customStyle={styles.emptyResultsText}>
              {i18n.t('search.no_results')}{' '}
              <CustomText align="left" color="limeGreen" weight="interBold" size="medium">
                {watch(name)}
              </CustomText>
            </CustomText>
          </View>
        )}
      </>
    </View>
  );
};

export default React.memo(Field);
