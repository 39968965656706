import React, { memo, useMemo, useState } from 'react';
import { ActivityIndicator, Keyboard, Pressable, View } from 'react-native';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { CommonActions, useNavigation } from '@react-navigation/core';

import { Colors } from '@theme';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { capitalizeFirstLetters } from '@utils/common';
import TimeAgo from '@atoms/TimeAgo';
import Reply from '@assets/svg/Reply';
import Verified from '@assets/svg/Verified';
import CommentMenu from '@assets/svg/CommentMenu';
import { useStore, authTokenSelector } from '@store/index';
import { useUserData } from '@services/useUserData';
import { useResponsecomment } from '@services/useComments';
import toast from '@utils/toast';
import Trash from '@assets/svg/Trash';
import { LikeCommentCTA } from '@molecules/LikeCommentCTA';
import Report from '@assets/svg/Report';
import PopUpMenu from '@molecules/PopUpMenu';

import CommentField from '../CommentField';
import Thread from '../Thread';
import useReportMenu from '../useReportMenu';

import { styles } from './styles';

const CommentWithThread = ({ comment }: any) => {
  const { id, description, response, user, createdDate, likesCount, likeByYou } = comment;
  const navigation = useNavigation();

  const authToken = useStore(authTokenSelector);
  const { data: userData, isLoading: userIsLoading } = useUserData(authToken || '');
  const [showMenu, setShowMenu] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [fieldFocus, setFieldFocus] = useState(false);
  const responseComment = useResponsecomment();
  const { handleDeleteCommentMenu, handleReportCommentMenu, deleteComment } = useReportMenu(id);

  const formData = useForm<any>();

  const i18n = useTranslations();

  const getReplyText = () => {
    if (response?.length === 1) return i18n.t('comments.view_reply');
    return `${i18n.t('comments.view')} ${response?.length} ${i18n.t('comments.replies')}`;
  };

  const onSubmit = (values) => {
    const postData = {
      description: values[`reply${id}`],
      comment: id
    };

    responseComment.mutate(postData, {
      onSuccess: () => {
        formData.resetField(`reply${id}`);
        toast.success({ title: i18n.t('comments.comment_published'), icon: 'success' });
        setShowReply(false);
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data?.message;
          toast.danger({
            title: message,
            icon: 'error'
          });
        } else {
          toast.danger({ icon: 'error', title: i18n.t('comments.comment_not_published') });
        }
      }
    });
  };

  const showCommentMenu = useMemo(() => userData?.id === user?.id, [user?.id, userData?.id]);

  const deleteItems = [
    { title: i18n.t('comments.delete_comment'), action: handleDeleteCommentMenu, Icon: Trash, danger: true }
  ];
  const reportItems = [
    { title: i18n.t('comments.report_comment'), action: handleReportCommentMenu, danger: true, report: true }
  ];

  const handleUser = async () => {
    const { userName } = user;
    const navigateAction = CommonActions.navigate({
      name: 'UserProfile',
      params: {
        userName
      },
      key: `UserProfile-${userName}`
    });
    navigation.dispatch(navigateAction);
  };

  return deleteComment.isLoading || userIsLoading ? (
    <View style={styles.loading}>
      <ActivityIndicator size="large" color={Colors.greySix} />
    </View>
  ) : (
    <View style={styles.container} key={id}>
      <PopUpMenu
        items={showCommentMenu ? deleteItems : reportItems}
        visible={showMenu}
        style={styles.popupMenu}
        onDismiss={() => setShowMenu(false)}
        fromResponse
      />
      <Pressable style={styles.user} onPress={handleUser}>
        <AvatarProfile
          customContainerStyle={styles.avatar}
          imageUrl={user.profileImage ?? undefined}
          backgroundUrl={user.backgroundProfileImage ?? undefined}
          offsetKitten={5}
        />
        <View style={styles.info}>
          <View style={styles.header}>
            <View style={styles.name}>
              <CustomText
                weight="interSemi"
                align="left"
                size="medium"
                color="white"
                customStyle={{ maxWidth: '25ch' }}
                numberOfLines={1}>
                {capitalizeFirstLetters(user?.fullName)}
              </CustomText>

              {user?.creator ? <Verified style={styles.creator} /> : null}
            </View>

            {!!authToken && (
              <Pressable style={styles.menu} onPress={() => setShowMenu((prev) => !prev)}>
                <CommentMenu />
              </Pressable>
            )}
          </View>
          <TimeAgo date={createdDate} />
        </View>
      </Pressable>
      <View style={styles.comment}>
        <CustomText weight="interSemi" align="left" size="medium" color="whiteTwo">
          {description}
        </CustomText>
      </View>
      <View style={styles.actions}>
        <LikeCommentCTA
          likeByYou={likeByYou}
          likesCount={likesCount || 0}
          commentId={id}
          authToken={authToken}
          navigation={navigation}
        />

        <Pressable
          onPress={() => {
            if (!authToken) {
              navigation.navigate('Login');
              return;
            }
            setShowReply((prev) => !prev);
          }}
          style={{ flexDirection: 'row' }}>
          <Reply showReply={showReply} />
          <CustomText
            customStyle={styles.replyNumber}
            weight="interSemi"
            align="left"
            size="medium"
            color={showReply ? 'limeGreen' : 'whiteTwo'}>
            {i18n.t('comments.reply')}
          </CustomText>
        </Pressable>
      </View>
      {showReply && (
        <CommentField
          formData={formData}
          isLoading={responseComment.isLoading}
          fieldFocus={fieldFocus}
          setFieldFocus={setFieldFocus}
          onSubmit={onSubmit}
          fieldName={`reply${id}`}
          fromResponse
          handleDismiss={() => setShowReply(false)}
        />
      )}
      {!!response?.length && (
        <Pressable
          style={styles.viewMore}
          onPress={() => {
            if (!authToken) {
              navigation.navigate('Login');
              return;
            }
            setShowComments((prev) => !prev);
          }}>
          <CustomText
            customStyle={styles.underline}
            weight="interSemi"
            size="medium"
            align="left"
            color={showComments ? 'limeGreen' : 'whiteTwo'}>
            {getReplyText()}
          </CustomText>
        </Pressable>
      )}
      {showComments && <Thread comment={comment} userId={userData?.id} skeletons={response?.length} />}
    </View>
  );
};

export default memo(CommentWithThread);
