import { useEffect, useState } from 'react';

const useGPTAdDetection = (adUnitPath: string) => {
  // states
  const [isAdDisplayed, setIsAdDisplayed] = useState<boolean | undefined>(undefined);

  // constants
  const googletag = window.googletag || {};

  // effects
  useEffect(() => {
    googletag.cmd = googletag.cmd || [];
    const handleSlotRenderEnded = (event) => {
      if (event.slot.getAdUnitPath() === adUnitPath) {
        if (event.isEmpty) {
          console.log('Ad was not displayed');
          setIsAdDisplayed(false);
        } else {
          console.log('Ad was displayed');
          setIsAdDisplayed(true);
        }
      }
    };

    if (typeof googletag?.apiReady === 'undefined' || !googletag?.apiReady) {
      console.log('Ad was not displayed');

      return setIsAdDisplayed(false);
    }
    googletag.pubads().addEventListener('slotRenderEnded', handleSlotRenderEnded);

    return () => {
      googletag.pubads().removeEventListener('slotRenderEnded', handleSlotRenderEnded);
    };
  }, [adUnitPath, googletag]);
  return isAdDisplayed;
};

export default useGPTAdDetection;
