import React, { FC, useState } from 'react';
import Tab from '@organisms/Tab';
import { ITabsProps } from './types';
import { ScrollView, View } from 'react-native';
import { useTranslations } from '@hooks/useTranslation';
import { styles } from './styles';

const CustomTabs: FC<ITabsProps> = ({ tabs }) => {
  // states
  const [activeTab, setActiveTab] = useState<number>(0);

  // hooks
  const i18n = useTranslations();
  // function
  function handleTabClick(index: number): void {
    setActiveTab(index);
  }

  // render
  return (
    <View style={styles.main}>
      <View style={styles.tabWrapper}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={i18n.t(tab.title).toString()}
            onPress={() => {
              if (tab.disabled) return;
              handleTabClick(index);
            }}
            isActive={index === activeTab}
          />
        ))}
        <View style={styles.divider} />
      </View>
      <ScrollView style={styles.body}>{tabs[activeTab].children}</ScrollView>
    </View>
  );
};

export default CustomTabs;
