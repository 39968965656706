import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard, View, StyleSheet } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import BioIcon from '@assets/svg/BioIcon';
import Button from '@atoms/Button';
import { FooterButton } from '@atoms/FooterButton';
import { authTokenSelector, setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import { RootStackNavigator } from 'app/navigation/types';
import toast from '@utils/toast';
import { useUpdateBio } from '@services/useUpdateBio';
import { useUserData } from '@services/useUserData';
import { WINDOW_WIDTH, isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import { Spacing } from '@theme';

import { styles } from '../styles';
import { ScreenTitle } from '..';

const screenStyles = StyleSheet.create({
  screenContainer: {
    marginRight: WINDOW_WIDTH > 1500 ? 370 : 0,
    paddingTop: Spacing.LARGE
  },
  screenInnerContainer: {
    maxWidth: 900
  },
  body: {
    paddingTop: Spacing.LARGE
  }
});

type ChangeBioProps = NativeStackScreenProps<RootStackNavigator, 'ChangeBio'>;

interface ChangeBioParams {
  bio: string;
}

const ChangeBio = ({ navigation }: ChangeBioProps) => {
  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);
  const authToken = useStore(authTokenSelector);
  const i18n = useTranslations();
  const updateBio = useUpdateBio(authToken || '');
  const { data: userData, isLoading: isLoadingUserData } = useUserData(authToken || '');

  const cancelChangeBio = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm<ChangeBioParams>({
    mode: 'onChange'
  });

  const maxLength = 160;

  const charactersLeft = maxLength ? maxLength - (watch('bio') ? Array.from(watch('bio')).length : 0) : 0;

  const newBio = watch('bio');

  const isDisabled = !userData || !!errors?.bio || (!newBio && !userData.bio) || newBio === userData.bio;

  function handleGoBack(): void {
    if (isWebResponsive) {
      navigation.goBack();
      return;
    }
    navigation.replace('AccountSettings');
  }

  const onSubmit = (values: ChangeBioParams) => {
    toggleModal();
    setModalInfo(undefined);
    const dataToSend = {
      newBio: values.bio
    };
    updateBio.mutate(dataToSend, {
      onSuccess: () => {
        toast.success({ icon: 'success', title: i18n.t('account_settings.changes_saved') });
        handleGoBack();
      },
      onError: () => {
        toast.danger({ icon: 'error', title: i18n.t('account_settings.changes_not_saved') });
        handleGoBack();
      }
    });
  };

  const handleChangeBio = () => {
    Keyboard.dismiss();
    setModalInfo({
      message: i18n.t('account_settings.change_confirm_message'),
      confirmText: i18n.t('account_settings.change_confirm_text'),
      cancelText: i18n.t('account_settings.change_cancel_text'),
      confirmAction: handleSubmit(onSubmit),
      cancelAction: () => cancelChangeBio(),
      bottomFixed: false
    });
    toggleModal();
  };

  return (
    <Layout
      padding={isMobile}
      hasBottomCTAS={
        <FooterButton>
          <Button
            loading={updateBio.isLoading}
            customStyle={styles.footerButton}
            customTextStyle={styles.footerButtonText}
            disabled={isDisabled}
            onPress={handleChangeBio}>
            {i18n.t('account_settings.change')}
          </Button>
        </FooterButton>
      }
      verticallyCentered={isDesktop}>
      <View
        style={[styles.screenContainer, isDesktop && !isWebResponsive ? screenStyles.screenContainer : {}]}>
        <View
          style={[
            styles.screenInnerContainer,
            isDesktop && !isWebResponsive ? screenStyles.screenInnerContainer : {}
          ]}>
          {isMobile || isWebResponsive ? (
            <View style={styles.goBack}>
              <GoBackButton goBackAction={handleGoBack} />
            </View>
          ) : null}
          <ScreenTitle>{i18n.t('account_settings.change_bio')}</ScreenTitle>
          <View style={[styles.formBody, isDesktop && !isWebResponsive ? screenStyles.body : {}]}>
            <Field
              error={errors}
              icon={BioIcon}
              name="bio"
              control={control}
              multiline
              textAlignVertical="top"
              containerStyles={[{ alignItems: 'flex-start' }, isDesktop && { minHeight: 163 }]}
              customStyle={{ textAlignVertical: 'top', minHeight: 130, paddingTop: 0 }}
              getValues={getValues}
              loading={isLoadingUserData}
              placeholder={i18n.t('account_settings.bio_input_placeholder').toString()}
              rules={{ maxLength: { value: maxLength, message: 'default' } }}
              withoutErrorText
              defaultValue={userData?.bio || undefined}
            />
            {charactersLeft >= 0 && charactersLeft < maxLength ? (
              <CustomText align="left" color="greySix" weight="interSemi">
                {charactersLeft} {i18n.t('comments.chars_left')}
              </CustomText>
            ) : (
              <CustomText
                align="left"
                color={charactersLeft === maxLength ? 'greySix' : 'error'}
                weight="interSemi">
                {maxLength} {i18n.t('account_settings.characters_maximum')}
              </CustomText>
            )}
            {isDesktop && !isWebResponsive ? (
              <Button
                loading={updateBio.isLoading}
                customStyle={styles.footerButton}
                customTextStyle={styles.footerButtonText}
                disabled={isDisabled}
                onPress={handleChangeBio}>
                {i18n.t('account_settings.save_changes')}
              </Button>
            ) : null}
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default ChangeBio;
