export default function calculateDiffDays(firstDate: Date): number {
  var timeDiff: number = today.getTime() - firstDate.getTime();

  const daysDiff = Math.floor(timeDiff / millisecondsToDays);

  return daysDiff;
}

// helper
const millisecondsToDays: number = 1000 * 60 * 60 * 24;
const today = new Date();
