import { StyleSheet } from 'react-native';

import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { SCREEN_WIDTH, isTablet, isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    borderRadius: moderateScale(20),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: isWebResponsive ? moderateScale(24) : moderateScale(32),
    paddingHorizontal: isWebResponsive ? moderateScale(24) : moderateScale(48),
    marginBottom: isWebResponsive || isTablet ? moderateScale(20) : 0,
    minWidth: isTablet || isWebResponsive ? 0 : 595
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  titleContainer: {
    flex: isTablet ? 1.3 : 1
  },
  leftHalf: {
    flex: isTablet ? 1 : 0.75
  },
  rightHalf: {
    flex: isTablet ? 0.3 : 0.4
  },
  title: {
    marginBottom: isTablet ? 0 : moderateScale(Spacing.TINY * 1.5),
    flexWrap: 'nowrap',
    flex: 1
  },
  button: {
    alignSelf: 'flex-start',
    marginTop: Spacing.SMALL
  },
  rightIcon: {
    flex: 2,
    alignItems: 'center'
  },
  titleIcon: {
    display: 'flex',
    marginRight: Spacing.BIG
  },
  imageStyle: {
    width: isWebResponsive
      ? moderateScale(100)
      : SCREEN_WIDTH <= 1200
      ? moderateScale(180)
      : moderateScale(200),
    height: isWebResponsive
      ? moderateScale(100)
      : SCREEN_WIDTH <= 1200
      ? moderateScale(180)
      : moderateScale(200),
    // marginHorizontal: isWebResponsive ? 0 : moderateScale(20),
    alignSelf: 'flex-end'
  }
});
