import React, { useState } from 'react';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { CustomModal } from '@molecules/CustomModal';
import { Image, Pressable, View } from 'react-native';
import { styles } from './styles';
import OKcomputer from '@assets/img/desktopComputer.png';
import CloseIcon from '@assets/svg/CloseIcon';
import { Colors } from '@theme';
import { ParamListBase, useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

type Nav = NativeStackNavigationProp<ParamListBase, string, undefined>;

export const NotAvailableModal = (): JSX.Element => {
  // states
  const [openModal, setOpenModal] = useState<boolean>(true);

  //hooks
  const i18n = useTranslations();
  const { goBack, getState, navigate } = useNavigation<Nav>();

  // constants
  const { history } = getState();

  // functions
  function handleModal(): void {
    if (!history) {
      return navigate('Home');
    }
    goBack();
    setOpenModal((prev) => !prev);
  }

  //render
  return (
    <View style={styles.notAvailable}>
      <CustomModal visible={openModal} dontClose customCardStyle={styles.notAvailableModal}>
        <Pressable style={styles.close} onPress={handleModal}>
          <CloseIcon color={Colors.white} />
        </Pressable>
        <Image source={OKcomputer} style={styles.computer} />
        <View style={styles.textWrapper}>
          <CustomText size="xmedium" weight="interBold">
            {i18n.t('dashboard.my_earnings.NA_title')}
          </CustomText>
          <CustomText weight="interRegular" customStyle={styles.text}>
            {i18n.t('dashboard.my_earnings.mobile_not_available')}
          </CustomText>
        </View>
      </CustomModal>
    </View>
  );
};
