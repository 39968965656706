import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';

import 'video.js/dist/video-js.css';
import { Pressable, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/core';

import { BackButtonVideoAd } from '@assets/svg/BackButtonVideoAd';
import { formatTime } from '@utils/time';
import { HelpVideoAdIcon } from '@assets/svg/HelpVideoAdIcon';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Button from '@atoms/Button';
import { Advertising } from '@interfaces/gameDetail';
import useGAMVideoTag from '@hooks/useGAMVideoTag';

import { styles } from './styles';

interface AdVideoProps {
  handleBack: () => void;
  handlePlay: () => void;
  gatoDirectSaleVideo?: Advertising;
}

const AdVideo = ({ handleBack, handlePlay, gatoDirectSaleVideo }: AdVideoProps) => {
  const i18n = useTranslations();
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const [countdownTimer, setCountdownTimer] = useState<string>('00:00');
  const [countdownTimerSeconds, setCountdownTimerSeconds] = useState<number>(0);
  const [adManager, setAdManager] = useState<any>(null);
  const [loadedDirectSale, setLoadedDirectSale] = useState<boolean>(false);
  const [showClose, setShowClose] = useState<boolean>(false);
  const gamTag = useGAMVideoTag();

  const navigation = useNavigation();
  navigation.setOptions({ headerShown: false });

  setTimeout(() => {
    setShowClose(true);
  }, 10000);

  const videoOptions = {
    autoplay: 'play',
    controls: false,
    sources: [
      {
        src: 'https://gato-files-prod.s3.amazonaws.com/ads-images/placeholder-video-ad.mp4',
        type: 'video/mp4',
      },
    ],
  };

  const imaOptions = {
    adTagUrl: gamTag,
    showCountdown: true,
    contribAdsSettings: {
      allowVjsAutoplay: true,
    },
    disableAdControls: true,
    disableCustomPlaybackForIOS10Plus: true,
  };

  // Initialize player
  useEffect(() => {
    if (!playerRef.current && gamTag) {
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoElement.style.width = '100%';
      videoElement.style.height = '100%';
      videoElement.setAttribute('playsinline', 'true');
      videoRef.current?.appendChild(videoElement);

      if (gatoDirectSaleVideo?.direct_sale) {
        videoOptions.sources = [
          {
            src: gatoDirectSaleVideo.imageUrl,
            type: 'video/mp4',
          },
        ];

        playerRef.current = videojs(videoElement, videoOptions, () => {
          videojs.log('Your player is ready!');
        });

        setLoadedDirectSale(true);

        return;
      }

      playerRef.current = videojs(videoElement, videoOptions, () => {
        videojs.log('Your player is ready!');
      });

      imaOptions.adTagUrl = gamTag;
      playerRef.current.ima(imaOptions);

      playerRef.current.on('ads-manager', (response) => {
        const { adsManager } = response;

        setAdManager(adsManager);
      });
    }
  }, [videoRef, gamTag]);

  // Cleanup player
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  // Get remaining time
  useEffect(() => {
    let timerId: any = null;
    if (adManager) {
      timerId = setInterval(() => {
        const timeRemaining = adManager.getRemainingTime();
        const formattedTimeRemaining = formatTime(timeRemaining);

        setCountdownTimerSeconds(timeRemaining);
        setCountdownTimer(formattedTimeRemaining);
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [adManager]);

  // Get remaining time
  useEffect(() => {
    let timerId: any = null;
    if (loadedDirectSale) {
      timerId = setInterval(() => {
        const timeRemaining = playerRef.current.remainingTime();
        const formattedTimeRemaining = formatTime(timeRemaining);

        setCountdownTimerSeconds(timeRemaining);
        setCountdownTimer(formattedTimeRemaining);
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [loadedDirectSale]);

  useEffect(() => {
    if (countdownTimerSeconds < 0) {
      handlePlay();
    }

    if (loadedDirectSale && countdownTimerSeconds <= 0) {
      handlePlay();
    }
  }, [countdownTimerSeconds]);

  return (
    <div data-vjs-player style={styles.container}>
      <AdTopBar handleBack={handleBack} />
      <RemainingTime remainingTime={countdownTimer} />
      <div ref={videoRef} style={styles.video} />
      {showClose && (
        <Button
          customStyle={styles.footerButton}
          customTextStyle={styles.footerButtonText}
          onPress={handlePlay}>
          {i18n.t('game_page.skip_ad')}
        </Button>
      )}
    </div>
  );
};

interface AdTopBarProps {
  handleBack: () => void;
}

const AdTopBar = ({ handleBack }: AdTopBarProps) => {
  const { top, bottom } = useSafeAreaInsets();
  return (
    <View style={[styles.headerTop, { top: top + 20 }]}>
      <Pressable onPress={handleBack}>
        <View>
          <BackButtonVideoAd />
        </View>
      </Pressable>
    </View>
  );
};

interface RemainingTimeProps {
  remainingTime: string;
}

const RemainingTime = ({ remainingTime }: RemainingTimeProps) => {
  const i18n = useTranslations();
  const { top, bottom } = useSafeAreaInsets();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <>
      <View style={[styles.content, { top: top + 24 }]} pointerEvents="none">
        <View style={[styles.row, styles.rowheader]}>
          <View style={styles.row}>
            <Pressable onPress={() => setShowTooltip((prev) => !prev)}>
              <View style={styles.tooltipCta}>
                <CustomText customStyle={styles.tooltipUi} weight="interSemi" color="white">
                  {i18n.t('game_page.tooltip_button_title')}
                </CustomText>
              </View>
            </Pressable>
            <Pressable onPress={() => setShowTooltip((prev) => !prev)}>
              <HelpVideoAdIcon fill="rgba(255,255,255,.5)" style={styles.ml6} />
            </Pressable>
          </View>
        </View>
        <View style={styles.seconds}>
          <CustomText align="left" weight="interSemi" color="white">
            {remainingTime}
          </CustomText>
        </View>
      </View>
      {showTooltip && (
        <Pressable style={[styles.tooltipContainer, { top: top + 80 }]} onPress={() => setShowTooltip(false)}>
          <>
            <View style={styles.tooltipIndicator} />
            <View style={styles.row}>
              <HelpVideoAdIcon fill="#E5F68C" style={styles.mr6} />
              <CustomText weight="interSemi" align="left" color="white">
                {i18n.t('game_page.tooltip_title')}
              </CustomText>
            </View>
            <CustomText
              customStyle={styles.tooltipDescription}
              weight="interMedium"
              align="left"
              size="msmall">
              {i18n.t('game_page.tooltip_description')}
            </CustomText>
          </>
        </Pressable>
      )}
    </>
  );
};

export default AdVideo;
