import { View, Text, Linking } from 'react-native';
import React from 'react';
import { MotiView } from 'moti';

import { Colors } from '@theme';
import Button from '@atoms/Button';
import { GATOIcon } from '@assets/svg/GATOIcon';
import { URLs } from '@utils/shareApp';
import { isBrowseriniOS } from '@utils/iOSBrowser';
import { useTranslations } from '@hooks/useTranslation';

import { styles } from './styles';
import { TABBAR_HEIGHT } from '@constants/platform';
import { downloadAppModalOpen, setDownloadAppModal, useStore } from '@store/index';

// TODO: check com.gato.app linking to dynamically render button title and change primary CTA function
export const DownloadCTA = () => {
  // hooks
  const i18n = useTranslations();
  const closeModal = useStore(setDownloadAppModal);
  const openModal = useStore(downloadAppModalOpen);

  // constants
  const iOS: boolean = isBrowseriniOS();
  const height: number = TABBAR_HEIGHT * 2.6;

  //   functions
  function handleActionSheet(): void {
    closeModal(false);
  }

  function handleRedirect(): Promise<any> {
    handleActionSheet();
    return Linking.openURL(AppStoresLinking[iOS ? 'ios' : 'android']);
  }

  // render
  if (!openModal) {
    return null;
  }
  return (
    <MotiView
      from={{ bottom: -height, opacity: 0 }}
      animate={{ bottom: 0, opacity: 1 }}
      transition={{ type: 'timing', duration: 300 }}
      exit={{ bottom: height, opacity: 0 }}
      delay={1000}
      style={styles.mainContainer}>
      <View style={styles.textWrapper}>
        <GATOIcon />
        <Text style={styles.text}>
          <Text style={{ fontWeight: '700' }}>{i18n.t('download.download_gato_app')}</Text>{' '}
          {i18n.t('download.play_statement')}
        </Text>
      </View>
      <View style={styles.buttonWrapper}>
        <Button
          onPress={handleActionSheet}
          customStyle={[styles.button, { backgroundColor: Colors.greyThree }]}
          customTextStyle={styles.secondaryButtonText}>
          {i18n.t('download.decline_action')}
        </Button>
        <Button
          onPress={handleRedirect}
          customStyle={styles.button}
          customTextStyle={styles.primaryButtonText}>
          {i18n.t('download.download_action')}
        </Button>
      </View>
    </MotiView>
  );
};

type MobilePlatforms = 'ios' | 'android';

type URLType = {
  [x in MobilePlatforms]: string;
};

const AppStoresLinking: URLType = {
  android: URLs.android,
  ios: URLs.ios
};
