import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  notAvailable: { flex: 1, backgroundColor: Colors.black },
  notAvailableModal: {
    width: moderateScale(312),
    height: moderateScale(294),
    padding: moderateScale(24),
    paddingBottom: moderateScale(48),
    justifyContent: 'space-around',
    position: 'relative'
  },
  close: { position: 'absolute', top: moderateScale(10), right: moderateScale(10) },
  computer: {
    width: moderateScale(100),
    height: moderateScale(82),
    alignSelf: 'center'
  },
  text: { marginVertical: moderateScale(8) },
  textWrapper: { width: moderateScale(264), padding: moderateScale(8) }
});
