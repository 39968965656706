import { StyleSheet } from 'react-native';

import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { SCREEN_HEIGHT } from '@constants/platform';
import { BIG, LARGE } from 'app/theme/spacing';
import { Colors } from '@theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: SCREEN_HEIGHT - moderateScale(120)
  },
  desktopContainer: {
    flexDirection: 'row'
  },
  desktopForm: {
    flex: 2,
    width: moderateScale(300),
    maxHeight: moderateVerticalScale(517),
    marginBottom: LARGE
  },
  onboarding: {
    flex: 0.5,
    backgroundColor: Colors.blackTwo,
    justifyContent: 'center'
  },
  description: {
    marginTop: moderateScale(16),
    marginBottom: moderateScale(30)
  },
  footer: { justifyContent: 'flex-end', alignItems: 'center', flex: 1 },
  underline: {
    textDecorationLine: 'underline'
  },
  button: {
    width: '100%',
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(20)
  },
  register: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(20)
  },
  logoImage: {
    height: 42,
    width: 125,
    marginBottom: BIG,
    alignSelf: 'center'
  }
});
