import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackNavigator } from 'app/navigation/types';

export type DeleteAccountProps = NativeStackScreenProps<RootStackNavigator, 'DeleteAccountWithoutLogin'>;

export interface DeleteAccountParams {
  password: string;
  emailOrUsername: string;
}

export enum DeleteAccountStepEnum {
  DELETE_FORM = 'form',
  DELETE_SUCCESS = 'success'
}

export const INITIAL_VALUE: DeleteAccountStepEnum = DeleteAccountStepEnum.DELETE_FORM;
