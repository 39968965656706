import React, { FC } from 'react';
import { Path, Svg, SvgProps } from 'react-native-svg';

export const BankIcon: FC<SvgProps> = (props): JSX.Element => {
  //constants

  //states

  //hooks

  //functions

  //effects

  //render

  return (
    <Svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <Path
        d="M1.03293 8.75L18.9671 8.75C19.9872 8.75 20.3878 7.39858 19.539 6.82059L11.1438 1.10375C10.4512 0.632083 9.54882 0.632083 8.85618 1.10375L0.461015 6.82059C-0.387764 7.39858 0.0128218 8.75 1.03293 8.75Z"
        fill="#E5F68C"
      />
      <Path d="M3.5 10.25H6.5V15.25H3.5V10.25Z" fill="#E5F68C" />
      <Path d="M16.5 10.25H13.5V15.25H16.5V10.25Z" fill="#E5F68C" />
      <Path d="M8.5 10.25H11.5V15.25H8.5V10.25Z" fill="#E5F68C" />
      <Path
        d="M18.2764 17.3028C18.107 16.964 17.7607 16.75 17.382 16.75L2.61803 16.75C2.23926 16.75 1.893 16.964 1.72361 17.3028L0.723606 19.3028C0.391155 19.9677 0.874653 20.75 1.61803 20.75H18.382C19.1253 20.75 19.6088 19.9677 19.2764 19.3028L18.2764 17.3028Z"
        fill="#E5F68C"
      />
    </Svg>
  );
};
