import React, { FC } from 'react';
import { Path, Svg, SvgProps } from 'react-native-svg';

export const ChartIcon: FC<SvgProps> = (): JSX.Element => {
  //render
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 1.5C2.16421 1.5 2.5 1.83579 2.5 2.25V18.25C2.5 20.0449 3.95507 21.5 5.75 21.5H21.75C22.1642 21.5 22.5 21.8358 22.5 22.25C22.5 22.6642 22.1642 23 21.75 23H5.75C3.12665 23 1 20.8734 1 18.25V2.25C1 1.83579 1.33579 1.5 1.75 1.5ZM11.5 12.25C11.5 11.8358 11.1642 11.5 10.75 11.5C10.3358 11.5 10 11.8358 10 12.25V17.25C10 17.6642 10.3358 18 10.75 18C11.1642 18 11.5 17.6642 11.5 17.25V12.25ZM7.5 14.25C7.5 13.8358 7.16421 13.5 6.75 13.5C6.33579 13.5 6 13.8358 6 14.25V17.25C6 17.6642 6.33579 18 6.75 18C7.16421 18 7.5 17.6642 7.5 17.25V14.25ZM14.75 13.5C15.1642 13.5 15.5 13.8358 15.5 14.25V17.25C15.5 17.6642 15.1642 18 14.75 18C14.3358 18 14 17.6642 14 17.25V14.25C14 13.8358 14.3358 13.5 14.75 13.5ZM19.5 17.25V12.25C19.5 11.8358 19.1642 11.5 18.75 11.5C18.3358 11.5 18 11.8358 18 12.25V17.25C18 17.6642 18.3358 18 18.75 18C19.1642 18 19.5 17.6642 19.5 17.25ZM19.2803 6.78033C19.5732 6.48744 19.5732 6.01256 19.2803 5.71967C18.9874 5.42678 18.5126 5.42678 18.2197 5.71967L15.6339 8.30546C15.1457 8.79361 14.3543 8.79361 13.8661 8.30546L12.6945 7.13388C11.6206 6.05994 9.8794 6.05994 8.80546 7.13388L6.21967 9.71967C5.92678 10.0126 5.92678 10.4874 6.21967 10.7803C6.51256 11.0732 6.98744 11.0732 7.28033 10.7803L9.86612 8.19454C10.3543 7.70639 11.1457 7.70639 11.6339 8.19454L12.8055 9.36612C13.8794 10.4401 15.6206 10.4401 16.6945 9.36612L19.2803 6.78033Z"
        fill="#E5F68C"
      />
    </Svg>
  );
};
