import { StyleSheet } from 'react-native';

import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isTablet, isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    flexDirection: isTablet || isWebResponsive ? 'column' : 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: Spacing.BANNERBOTTOM - Spacing.BIG
  },
  banner: {
    maxWidth: isTablet || isWebResponsive ? '100%' : '48%',
    flex: 1
  },
  lottie: { width: moderateScale(72), height: moderateScale(33) }
});
