import { NativeModules, Platform } from 'react-native';
import * as Localization from 'expo-localization';

export enum LanguagesEnum {
  SPANISH_LANG = 'es',
  PORTUGUESE_LANG = 'pt',
  ENGLISH_LANG = 'en'
}

type LanguageLabelType = {
  [x in LanguagesEnum]: string;
};

export const LanguageLabel: LanguageLabelType = {
  [LanguagesEnum.SPANISH_LANG]: 'Español',
  [LanguagesEnum.PORTUGUESE_LANG]: 'Português',
  [LanguagesEnum.ENGLISH_LANG]: 'English'
};

export function getLanguage(): LanguagesEnum {
  const deviceLanguage: string =
    Platform.OS === 'ios'
      ? NativeModules.SettingsManager.settings.AppleLanguages[0] // iOS 13
      : Localization.locale;

  if (deviceLanguage.includes('es')) return LanguagesEnum.SPANISH_LANG;
  if (deviceLanguage.includes('pt')) return LanguagesEnum.PORTUGUESE_LANG;

  return LanguagesEnum.ENGLISH_LANG;
}
