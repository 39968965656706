import { useEffect, useState } from 'react';

import { gamTags, mobileGamTags, ratiosUpperLimits } from '@constants/gamVideoTags';
import { Ratios } from 'app/enums/aspectRatios.enum';

const REFERENCE_WIDTH = 1280;

const useGAMVideoTag = () => {
  // Get width and height of screen
  const width = window.innerWidth;
  const height = window.innerHeight;
  const aspectRatio = width / height;
  const [gamTag, setGamTag] = useState<string>('');

  useEffect(() => {
    // Get the aspect ratio of the screen (9:16, 4:3, 16:9) between of ranges of ratiosUpperLimits
    const ratioUpperLimit = Object.values(ratiosUpperLimits).find((ratio) => aspectRatio <= ratio);

    // Get the key of the ratioUpperLimit
    const ratioKey = Object.keys(ratiosUpperLimits).find((key) => ratiosUpperLimits[key] === ratioUpperLimit);

    // Get the GAM tag
    const tag = gamTags[ratioKey as Ratios];

    if (ratioKey === Ratios['16:9'] && width < REFERENCE_WIDTH) {
      setGamTag(mobileGamTags['16:9']);
    } else {
      setGamTag(tag);
    }
  }, []);

  return gamTag;
};

export default useGAMVideoTag;
