/* eslint-disable prefer-arrow-callback */
import React from 'react';
import { useEffect } from 'react';

interface IUseGPT {
  path: string;
  size: number[];
  id: string;
}

const useGPT = ({ path, size, id }: IUseGPT) => {
  const [adError, setAdError] = React.useState(false);
  useEffect(() => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    let slot = undefined;
    googletag.cmd.push(function () {
      try {
        slot = googletag.defineSlot(path, size, id)
          .addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();
      }
      catch (e) {
        setAdError(true);
      }
    });
    googletag.cmd.push(function () {
      try {
        googletag.display(slot.getSlotElementId());
        setAdError(false);
      }
      catch (e) {
        setAdError(true);
      }
    });

    return () => {
      googletag.cmd.push(function () {
        if (slot) {
          googletag.destroySlots([slot]); // Only destroy this slot
        }
        setAdError(false);
      });
    }
  }, []);

  return adError;
};

export default useGPT;
