import { LinearGradient } from 'expo-linear-gradient';
import React, { FC } from 'react';
import { ColorValue, Image, Linking, TextStyle, View } from 'react-native';
import Button from '@atoms/Button';
import CustomText, { Sizes } from '@atoms/CustomText';
import { styles } from './styles';
import { isTablet, isWebResponsive } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import { useUploadingGameLink } from '@hooks/useUploadingGameLink';
import { GatoBannerThemeTypes, GatoBannerUseCase, GatoBannersProps } from './types';
import InvitePNG from '@assets/img/invite-illustration.png';
import UploadPNG from '@assets/img/upload-game-illustration.png';
import UploadESPNG from '@assets/img/upload-game-illustration-SPANISH.png';
import { Colors } from '@theme';
import ShareAction from '@utils/shareApp';
import { LanguagesEnum, getLanguage } from '@utils/getLanguage';
import { moderateScale } from '@utils/scaling';
import { SIZES } from 'app/theme/fonts';

const GatoBanner: FC<GatoBannersProps> = ({ useCase, containerStyle }): JSX.Element => {
  // hooks
  const i18n = useTranslations();
  const link = useUploadingGameLink();
  // constants
  const titleFontSize: Sizes = isWebResponsive ? 'xmedium' : isTablet ? 'xlarge' : 'xxlarge';
  const buttonTextSize: Pick<TextStyle, 'fontSize'> = isWebResponsive
    ? { fontSize: SIZES.medium }
    : { fontSize: SIZES.lMedium };
  const buttonTextColor: ColorValue = theme[useCase].button.contrastText ? Colors.white : Colors.black;

  // functions
  function handlePress(): void {
    if (useCase === GatoBannerUseCase.SHARE) {
      ShareAction();
      return;
    }
    Linking.openURL(link);
  }

  // render
  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 0.5, y: 1 }}
      locations={[0.3, 0.9]}
      colors={theme[useCase].gradientColors}
      style={[styles.container, containerStyle]}>
      <View style={[styles.leftHalf]}>
        <View style={[styles.header]}>
          <View style={styles.titleContainer}>
            <CustomText
              numberOfLines={isTablet ? 1 : 2}
              customStyle={styles.title}
              align="left"
              weight="interBlack"
              size={titleFontSize}
              color={theme[useCase].textColor}>
              {i18n.t(theme[useCase].title)}
            </CustomText>
            {isWebResponsive ? null : (
              <CustomText align="left" weight="interRegular" size={'big'} color={theme[useCase].textColor}>
                {i18n.t(theme[useCase].subtitle)}
              </CustomText>
            )}
          </View>
        </View>
        <Button
          onPress={handlePress}
          customStyle={[styles.button, { backgroundColor: theme[useCase].button.bgColor }]}
          customTextStyle={[{ color: buttonTextColor, fontWeight: '800' }, buttonTextSize]}>
          {i18n.t(theme[useCase].button.title)}
        </Button>
      </View>
      <View style={[styles.rightHalf]}>
        <Image resizeMode="cover" source={theme[useCase].illustration} style={styles.imageStyle} />
      </View>
    </LinearGradient>
  );
};

export default GatoBanner;

// helpers
const lang = getLanguage();

const theme: GatoBannerThemeTypes = {
  [GatoBannerUseCase.CREATOR]: {
    gradientColors: ['#7154FF', '#3A298E'],
    button: {
      title: 'settings.upload_game',
      bgColor: Colors.limeGreen,
      contrastText: false
    },
    title: 'home.creator_title',
    subtitle: 'home.creator_subtitle',
    textColor: 'white',
    illustration: lang === LanguagesEnum.ENGLISH_LANG ? UploadPNG : UploadESPNG
  },
  [GatoBannerUseCase.SHARE]: {
    gradientColors: ['#E5F68C', '#BECF67'],
    button: { title: 'home.share_app', bgColor: Colors.blackTwo, contrastText: true },
    title: 'home.invite_friends',
    subtitle: 'home.invite_friends_subtitle',
    textColor: 'blackTwo',
    illustration: InvitePNG
  }
};
