import React from 'react';
import { Image, View } from 'react-native';
import { styles } from './styles';
import { IconBadge } from '@molecules/IconBadge';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import SadCat from '@assets/img/sadCat.png';
import { ChartIcon } from '@assets/svg/chartIcon';

export const TransactionsHistoryCard = (): JSX.Element => {
  //hooks
  const i18n = useTranslations();

  //render
  return (
    <View style={styles.mainContainer}>
      <View style={styles.flexWrapper}>
        <IconBadge icon={<ChartIcon />} />
        <CustomText weight="interBold" align="left" size="xmedium" customStyle={{ marginHorizontal: 10 }}>
          {i18n.t('dashboard.my_earnings.transactions.transaction_history')}
        </CustomText>
      </View>
      {/* TODO: View table components */}
      {/* <View style={styles.historyContainer}>
        <CustomText weight="interBold" size="msmall" align="left" color="textInputDefault">
          {i18n.t('dashboard.my_earnings.transactions.recent_history')}
        </CustomText>
      </View> */}
      <View style={styles.emptyHistoryContainer}>
        <Image source={SadCat} style={styles.image} />
        <CustomText weight="interRegular" size="medium">
          {i18n.t('dashboard.my_earnings.transactions.empty_transaction_history')}
        </CustomText>
      </View>
    </View>
  );
};
