import React, { FC } from 'react';
import { Pressable } from 'react-native';
import { TabProps } from './types';
import CustomText from '@atoms/CustomText';
import { styles } from './styles';
const Tab: FC<TabProps> = ({ label, onPress, isActive }) => {
  // constants
  const style = styles(isActive);

  // render
  return (
    <Pressable onPress={onPress} style={style.tab}>
      <CustomText color={isActive ? 'limeGreen' : 'textInputDefault'} weight="interSemi" size="medium">
        {label}
      </CustomText>
    </Pressable>
  );
};

export default Tab;
