/* eslint-disable @typescript-eslint/naming-convention */
import React, { useRef, useState } from 'react';
import { View, Linking, Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';

import PopUpMenu from '@molecules/PopUpMenu';
import { useTranslations } from '@hooks/useTranslation';
import UserIcon from '@assets/svg/UserIcon';
import AboutGatoIcon from '@assets/svg/AboutGatoIcon';
import AccountSettingsIcon from '@assets/svg/AccountSettingsIcon';
import HelpIcon from '@assets/svg/HelpIcon';
import LogoutIcon from '@assets/svg/LogoutIcon';
import { useLogoutMessage } from '@hooks/useLogoutMessage';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { isWebResponsive } from '@constants/platform';
import links from '@constants/links';
import GetLogo from '@molecules/GatoLogo';
import { useMyProfile } from '@services/useMyProfile';
import { DashboardIcon } from '@assets/svg/Dashboard';

import DesktopTopbar from './DesktopTopbar/DesktopTopbar';
import ResponsiveTopbar from './ResponsiveTopbar/ResponsiveTopbar';
import { styles } from './styles';
import { rootProp } from '../types';

interface WebNavbarProps {
  HeaderBackground?: () => React.ReactNode;
}

const WebNavbar = ({ HeaderBackground }: WebNavbarProps) => {
  const i18n = useTranslations();
  const [menuVisible, setMenuVisible] = useState(false);

  const navigation = useNavigation<rootProp>();
  const { data } = useMyProfile();

  const logoutMessage = useLogoutMessage();
  const ref = useRef(null);

  // const countryISOCode = useCountryIsoCode();

  // const getAdWidth = () => {
  //   if (isWebResponsive) {
  //     return BANNER_SIZE.HEADER_BANNER_MOBILE.WIDTH;
  //   }
  //   return BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.WIDTH;
  // };

  // const getAdHeight = () => {
  //   if (isWebResponsive) {
  //     return BANNER_SIZE.HEADER_BANNER_MOBILE.HEIGHT;
  //   }
  //   return BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.HEIGHT;
  // };

  // const { data, isLoading } = useAdvertisingByDimension(getAdWidth(), getAdHeight(), 'IMAGE', countryISOCode);

  function goHome(): void {
    navigation.push('Home');
  }
  const handleUserMenuToggle = () => setMenuVisible(() => !menuVisible);
  useOnClickOutside(ref, () => setMenuVisible(false));

  const handleLogout = () => {
    logoutMessage();
  };

  const handleHelp = () => {
    Linking.openURL(
      'mailto:hello@gato.us?subject=Support request from GATO&body=Hi there,\n\nThank you for contacting GATO.\n\nHow we can assist you? Feel free to write it down.\n\n## Please type your request above this line ##\n\nOur support staff will respond as soon as possible.\n\nCheers,\nGATO'
    );
  };

  const handleAbout = () => {
    Linking.openURL(links.gatoAboutLink);
  };

  const menuItems = [
    { title: i18n.t('settings.profile'), route: `MyProfile`, Icon: UserIcon },
    { title: i18n.t('dashboard.dashboard'), route: 'Dashboard', Icon: DashboardIcon },
    { title: i18n.t('settings.account_settings'), route: 'AccountSettings', Icon: AccountSettingsIcon },
    { title: i18n.t('settings.need_help'), action: handleHelp, Icon: HelpIcon },
    { title: i18n.t('settings.about_GATO'), action: handleAbout, Icon: AboutGatoIcon },
    { title: i18n.t('settings.logout'), action: handleLogout, Icon: LogoutIcon, danger: true }
  ];

  // const getAnalyticsEvent = () => {
  //   if (route && route.search('game') !== -1) {
  //     return AnalyticsEvents.AD_GAME_PAGE_CLICK;
  //   }
  //   return AnalyticsEvents.AD_HOME_CLICK;
  // };

  // const getBannerWidth = () => {
  //   if (isWebResponsive) {
  //     return BANNER_SIZE.HEADER_BANNER_MOBILE.WIDTH;
  //   }
  //   return BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.WIDTH;
  // };

  // const getBannerHeight = () => {
  //   if (isWebResponsive) {
  //     return BANNER_SIZE.HEADER_BANNER_MOBILE.HEIGHT;
  //   }
  //   return BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.HEIGHT;
  // };

  return (
    <View
      style={[
        styles.outerContainer,
        HeaderBackground !== undefined && styles.hover,
        HeaderBackground !== undefined && styles.transparent
      ]}>
      {HeaderBackground !== undefined && (
        <LinearGradient
          start={{ x: 1, y: 0 }}
          end={{ x: 1, y: 1 }}
          locations={[0, 1]}
          colors={['rgba(0,0,0,0.6)', 'rgba(0,0,0,0.0)']}
          style={styles.transparentGradient}
        />
      )}

      {HeaderBackground && HeaderBackground()}

      {/* Header banner commented out for now */}
      {/* <View style={{ paddingTop: Spacing.SMALL, backgroundColor: isWebResponsive ? Colors.darkGrey : 'black' }}>
          <AdImageComponent width={getBannerWidth()} height={getBannerHeight()} analyticsEvent={getAnalyticsEvent()} adsenseType={'headerBanner'} />
        </View> */}

      <View style={[styles.container]} ref={ref}>
        <Pressable style={styles.logoImageContainer} onPress={goHome}>
          <GetLogo style={styles.logoImage} />
        </Pressable>
        {isWebResponsive ? (
          <ResponsiveTopbar />
        ) : (
          <DesktopTopbar handleUserMenuToggle={handleUserMenuToggle} />
        )}

        <PopUpMenu
          disabled={!data}
          visible={menuVisible}
          items={data?.type === 'PLAYER' ? menuItems.filter((el) => el.route !== 'Dashboard') : menuItems}
          style={styles.dropDownMenu}
        />
      </View>
    </View>
  );
};

export default WebNavbar;
