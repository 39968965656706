import CustomText from '@atoms/CustomText';
import { DESKTOP_CONTAINER_WIDTH, MIN_RESOLUTION_SUPPORTED, SCREEN_WIDTH } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import { EarningStatCard } from '@molecules/EarningStatCard';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { styles } from './styles';
import ItemDivider from '@atoms/ItemDivider';
import { AvailableFundsCard } from '@molecules/AvailableFunds';
import { TransactionsHistoryCard } from '@molecules/TransactionHistory';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import { yearsArray } from '../Analytics/helper';
import { SelectTheme, customSelectStyles } from '../Analytics/styles';
import { useGetGamesByCreator } from '@services/useGetGamesByCreator';
import { useUserData } from '@services/useUserData';
import { authTokenSelector, useStore } from '@store/index';
import { BalanceStatsEnum } from '@molecules/EarningStatCard/types';

export const BalanceScreen = (): JSX.Element => {
  // main dependencies boilerplate
  const i18n = useTranslations();
  const authToken = useStore(authTokenSelector);
  const userData = useUserData(authToken || '');

  // states
  const [games, setGames] = useState<unknown[]>([]);
  const [filters, setFilters] = useState<any>();
  const [gameSelected, setGameSelected] = useState<string>('');
  // constants
  const creatorId: number | undefined = userData?.data?.id;

  //hooks
  const { control } = useForm();
  const { data: creator } = useGetGamesByCreator(Number(creatorId), 'CREATOR');

  // functions
  function updateState(key: string, value: string | boolean): void {
    setFilters({
      ...filters,
      [key]: value
    });
  }

  // side effects
  useEffect(() => {
    if (creator) {
      const creatorGames = creator[0]?.games.map((game: any) => ({
        value: game.id,
        label: game.name
      }));
      creatorGames.unshift({
        value: '',
        label: i18n.t('dashboard.my_earnings.balance_info.all_games_filter')
      });

      setGames(creatorGames);
    }
  }, [creator]);

  //render
  if (SCREEN_WIDTH < DESKTOP_CONTAINER_WIDTH) {
    return (
      <View style={styles.mainContainer}>
        <CustomText>{i18n.t('dashboard.my_earnings.NA_title')}</CustomText>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View>
        <CustomText weight="interSemi" align="left" size="mlarge">
          {i18n.t('dashboard.my_earnings.title')}
        </CustomText>
        <View style={styles.cardsWrapper}>
          <AvailableFundsCard value={0} />
          <TransactionsHistoryCard />
        </View>
      </View>
      <View style={{ marginVertical: 20 }}>
        <ItemDivider />
      </View>
      <View>
        <View style={[styles.cardsWrapper, { zIndex: 99 }]}>
          <CustomText weight="interSemi" align="left" size="mlarge">
            {i18n.t('dashboard.my_earnings.balance_info.title')}
          </CustomText>
          {/* selects here */}
          <View style={styles.cardsWrapper}>
            <View style={styles.select}>
              <Controller
                name={'Games'}
                control={control}
                render={({ field }) => (
                  <Select
                    options={games}
                    placeholder={
                      gameSelected || i18n.t('dashboard.my_earnings.balance_info.all_games_filter')
                    }
                    styles={customSelectStyles}
                    theme={SelectTheme}
                    onChange={(e: SingleValue<any>) => {
                      setGameSelected(e?.label);
                      field.onChange(e?.value);
                      updateState('gameId', String(e?.value));
                    }}
                  />
                )}
              />
            </View>
            <View style={styles.select}>
              <Controller
                name="Date"
                control={control}
                defaultValue="2024"
                render={({ field }) => (
                  <Select
                    options={yearsArray}
                    placeholder="2024"
                    // isDisabled
                    styles={customSelectStyles}
                    theme={SelectTheme}
                    onChange={(e) => {
                      field.onChange(e?.value);
                      updateState('year', String(e?.value));
                    }}
                  />
                )}
              />
            </View>
          </View>
        </View>
        <View style={[styles.cardsWrapper, { zIndex: 1 }]}>
          <EarningStatCard
            title={i18n.t('dashboard.my_earnings.balance_info.total_profit')}
            value={0}
            format={BalanceStatsEnum.CURRENCY_NUMBER}
          />
          <EarningStatCard
            title={i18n.t('dashboard.my_earnings.balance_info.total_games_played')}
            value={0}
            format={BalanceStatsEnum.DECIMAL_NUMBER}
          />
          <EarningStatCard
            title={i18n.t('dashboard.my_earnings.balance_info.total_playtime')}
            value={0}
            format={BalanceStatsEnum.TIME_NUMBER}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};
