import { Pressable, View, ViewStyle } from 'react-native';
import React, { useCallback } from 'react';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring
} from 'react-native-reanimated';
import { useFocusEffect, useNavigation } from '@react-navigation/core';

import ChevronLeft from '@assets/svg/ChevronLeft';
import { isDesktop, isWebResponsive } from '@constants/platform';
import GetLogo from '@molecules/GatoLogo';

import { styles } from './styles';

type GoBackProps = {
  goBackAction?: () => void;
  fromRight?: boolean;
  customStyles?: ViewStyle;
  gatoLogo?: boolean;
};

const GoBackButton = ({
  goBackAction,
  fromRight,
  customStyles,
  gatoLogo = isDesktop && !isWebResponsive
}: GoBackProps) => {
  const navigation = useNavigation();
  const animation = useSharedValue(0);

  useFocusEffect(
    useCallback(() => {
      animation.value = withDelay(500, withSpring(1, { stiffness: 100, damping: 30 }));

      return () => {
        setTimeout(() => {
          animation.value = withSpring(0);
        }, 200);
      };
    }, [animation])
  );

  const leftStyle = useAnimatedStyle(
    () => ({
      transform: [{ translateX: interpolate(animation.value, [0, 1], [fromRight ? 100 : -100, 0]) }]
    }),
    []
  );

  const opacityStyle = useAnimatedStyle(
    () => ({
      opacity: interpolate(animation.value, [0, 1], [0, 1])
    }),
    []
  );
  const opacityStyleDesktop = isDesktop ? opacityStyle : null;

  const handleBack = () => {
    if (goBackAction) {
      goBackAction();
      return;
    } else {
      navigation.goBack();
    }
  };
  return (
    <Animated.View style={[leftStyle, styles.container, opacityStyleDesktop]}>
      <Pressable hitSlop={20} style={styles.header} onPress={handleBack}>
        <View style={[styles.header, customStyles]}>
          <ChevronLeft style={styles.chevron} />
        </View>
      </Pressable>
      {gatoLogo ? <GetLogo style={styles.logoImage} /> : null}
    </Animated.View>
  );
};

export default GoBackButton;
