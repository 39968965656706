// General / branding

export const white = '#ffffff';
export const whiteTwo = 'rgba(252, 252, 252, 0.6)';
export const black = '#000000';
export const blackTwo = '#1A1D1F';
export const blackThree = '#0B0B0D';
export const blackFour = '#111111';
export const purple = '#6546FC';
export const limeGreen = '#E5F68C';
export const surfaceBrandHover = '#96A353';
export const darkGrey = '#141416';
export const pinkBubblegum = '#FB8AD8';
export const liliac = '#BBACED';
export const pumpkin = '#FE713C';
export const grey = '#373548';
export const greyTwo = '#353945';
export const greyThree = '#717788';
export const greyFour = '#272B30';
export const greyFive = '#626166';
export const greySix = '#777E90';
export const greySeven = '#919499';
export const greyEight = '#6F767E';
export const socialBackground = '#1D1F23';
export const shareAppBackground = 'rgba(39, 43, 48, .5)';
export const shareAppDescription = '#5E6272';
export const userTypeBackground = '#353945';
export const lightGrey = '#EBEBEB';
export const glow = '#C9FFF5';
export const lightBlue = '#8AB9FF';
export const orangeStrong = '#DC3C00';
export const disabled = '#454B58';
export const whiteOpacity = (opacity: number) => `rgba(255,255,255, ${opacity})`;
export const limeOpacity = (opacity: number) => `rgba(229, 246, 140, ${opacity})`;
export const textInputDefault = '#A4ADC2';
// Tab bar
export const tabColorActive = purple;
export const tabColor = '#F4F5F6';

// Button colors
export const buttonPrimActive = limeGreen;
export const buttonPrimPressed = '#97A358';
export const buttonPrimText = '#0F0F15';

// Filter colors
export const filterItemBlack = 'rgba(26, 29, 31, 1)';
export const filterItemGrey = 'rgba(111,118,126,0.5)';
export const filterItemLime = 'rgba(229, 246, 140, 0.2)';
export const transparentWhite = 'rgba(255, 255, 255, 0)';
export const transparentBlack = 'rgba(0, 0, 0, 0)';

// Input colors
export const textInputGrey = 'rgba(111, 118, 126, 0.5)';

// Event colors
export const christmasRed = '#B5000A';

// Status

export const error = '#F2002C';
export const warning = '#E7B128';
export const inputError = '#DD438D';
export const gridoRedLight = '#F4D2D3';
export const orange = '#FF7A00';
export const orangeLight = '#FFE6C0';
export const yellow = '#FFC404';
export const yellowLight = '#FBF2DB';
export const success = '#37AC7B';
export const successLight = '#E0F8C2';
export const greenDark = '#6B9734';
export const greenLight = '#D6ECBC';

export const colors = {
  white,
  black,
  purple,
  limeGreen,
  darkGrey,
  pinkBubblegum,
  liliac,
  pumpkin,
  christmasRed,
  grey,
  lightGrey,
  whiteOpacity,
  tabColorActive,
  tabColor,
  buttonPrimActive,
  buttonPrimPressed,
  buttonPrimText,
  error,
  inputError,
  gridoRedLight,
  orange,
  orangeLight,
  yellow,
  yellowLight,
  success,
  successLight,
  greenDark,
  greenLight
};

export const shadow = {
  shadowColor: purple,
  shadowOpacity: 0.15,
  shadowRadius: 8,
  shadowOffset: {
    width: 0,
    height: 4
  },
  elevation: 7
};
