import React from 'react';
import { View, Pressable, Linking } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';

import { styles } from '../styles';

const NeedHelp = () => {
  const i18n = useTranslations();

  const handleContactGato = () => {
    Linking.openURL('mailto:hello@gato.us');
  };

  return (
    <View style={styles.contactWrapper}>
      <CustomText size="medium" weight="interBold" align="center">
        {`${i18n.t('account_settings.need_help')} `}
        <Pressable hitSlop={10} onPress={handleContactGato}>
          <CustomText customStyle={styles.contact} size="medium" weight="interBold" color="limeGreen">
            {i18n.t('account_settings.contact_us')}
          </CustomText>
        </Pressable>
      </CustomText>
    </View>
  );
};

export default NeedHelp;
