import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    marginTop: 10
  },
  fullNameContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center'
  },
  typeContainer: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  userType: {
    width: moderateScale(72),
    height: moderateScale(33),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: moderateScale(90),
    backgroundColor: Colors.userTypeBackground,
    marginLeft: 10
  },
  userTypeText: {
    textTransform: 'capitalize'
  },
  bio: {
    maxWidth: '75%',
    alignSelf: 'center',
    marginTop: moderateScale(20)
  }
});
