import React from 'react';
import { GoogleAdManagerUnitIdEnum, unitsIdData } from './constants';
import useGPT from '@hooks/useGPT';
import { View } from 'react-native';
import useGPTAdDetection from '@hooks/useGPTAdDetection';

export interface GoogleAdManagerBannerProps {
  unitId: GoogleAdManagerUnitIdEnum;
  adErrorPlaceholder: any;
}

const GoogleAdManagerBanner = ({ unitId, adErrorPlaceholder }: GoogleAdManagerBannerProps) => {
  let adError = useGPT({
    path: unitsIdData[unitId].path,
    size: [unitsIdData[unitId].size.width, unitsIdData[unitId].size.height],
    id: unitsIdData[unitId].id
  });

  let isAdDisplayed = useGPTAdDetection(unitsIdData[unitId].path);

  if (isAdDisplayed === false || adError === true) {
    return adErrorPlaceholder;
  }

  return (
    <View
      style={{
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <div
        id={unitsIdData[unitId].id}
        style={{
          width: unitsIdData[unitId].size.width,
          height: unitsIdData[unitId].size.height
        }}
      />
    </View>
  );
};

export default React.memo(GoogleAdManagerBanner);
