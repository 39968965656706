import React from 'react';
import { View } from 'react-native';
import GatoBanner from '@molecules/GatoBanner';

import { styles } from './styles';
import { GatoBannerUseCase } from '@molecules/GatoBanner/types';

const GatoBannersWrapper = () => (
  <View style={styles.container}>
    {Object.values(GatoBannerUseCase).map((el, i) => {
      return <GatoBanner useCase={el} key={i} containerStyle={styles.banner} />;
    })}
  </View>
);

export default GatoBannersWrapper;
