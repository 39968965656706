import React from 'react';
import { Path, Svg, SvgProps } from 'react-native-svg';

export const DashboardIcon = ({ fill, ...props }: SvgProps): JSX.Element => (
  <Svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 2.5C2.67157 2.5 2 3.17157 2 4C2 4.82843 2.67157 5.5 3.5 5.5H20.5C21.3284 5.5 22 4.82843 22 4C22 3.17157 21.3284 2.5 20.5 2.5H3.5ZM21 7H3V16.5C3 17.6046 3.89543 18.5 5 18.5H11.25V22.5C11.25 22.9142 11.5858 23.25 12 23.25C12.4142 23.25 12.75 22.9142 12.75 22.5V18.5H19C20.1046 18.5 21 17.6046 21 16.5V7ZM16.6431 10.886C16.8562 10.5308 16.741 10.0701 16.3859 9.85699C16.0307 9.64388 15.57 9.75906 15.3569 10.1142L14.2746 11.918C13.905 12.534 13.0344 12.5926 12.5856 12.0316C11.4839 10.6544 9.34655 10.7981 8.43912 12.3105L7.35687 14.1142C7.14376 14.4694 7.25893 14.9301 7.61412 15.1432C7.9693 15.3563 8.43 15.2412 8.64311 14.886L9.72536 13.0822C10.095 12.4662 10.9656 12.4077 11.4143 12.9686C12.5161 14.3459 14.6534 14.2021 15.5609 12.6897L16.6431 10.886Z"
      fill={fill || '#717788'}
    />
  </Svg>
);
