import { useQuery } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';

async function getDashboardCreator(
  creatorId: string,
  allGames: boolean,
  year: string,
  gameId: string,
  month: string,
  numberOfSessions: boolean,
  sessionTime: boolean,
  sessionAverage: boolean
) {
  const hasGame = gameId !== '' && gameId ? `&gameId=${gameId}` : '';

  const response = await axiosGamesInstance.get(
    `/game/dashboard-creator/${creatorId}?allGames=${!hasGame}&year=${year}${hasGame}&month=${month}&numberOfSessions=${numberOfSessions}&sessionTime=${sessionTime}&sessionAverage=${sessionAverage}`
  );

  return response.data;
}

export function useDashboardCreator(
  creatorId: string,
  allGames: boolean,
  year: string,
  gameId: string,
  month: string,
  numberOfSessions: boolean,
  sessionTime: boolean,
  sessionAverage: boolean
) {
  return useQuery(
    [QueryKeys.GET_DASHBOARD_CREATOR, creatorId],
    () =>
      getDashboardCreator(
        creatorId,
        allGames,
        year,
        gameId,
        month,
        numberOfSessions,
        sessionTime,
        sessionAverage
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!creatorId
    }
  );
}
