export type SocialMedia = 'email' | 'instagram' | 'facebook' | 'twitter';

export const OUR_SOCIAL_MEDIA: Record<SocialMedia, string> = {
  email: 'mailto:hello@gato.us',
  instagram: 'https://instagram.com/gatoisus',
  facebook: 'https://facebook.com/gatoisus',
  twitter: 'https://twitter.com/gatoisus'
};

export interface SectionProps {
  isSmallLayout?: boolean;
}
