import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AxiosError } from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard, View } from 'react-native';

import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useStatusBar } from '@hooks/useStatusBar';
import { useTranslations } from '@hooks/useTranslation';
import { ForgotPasswordParams } from '@interfaces/auth';
import Field from '@molecules/Field';
import { Layout } from '@organisms/Layout';
import { useForgotPassword } from '@services/useForgotPassword';
import toast from '@utils/toast';
import { RootStackNavigator } from 'app/navigation/types';
import GoBackButton from '@molecules/GoBackButton';
import { emailRegex } from '@utils/validations';
import EmailIcon from '@assets/svg/EmailIcon';
import DesktopCenteredContainer from '@atoms/DesktopCenteredContainer';
import { isDesktop, isWebResponsive } from '@constants/platform';
import { Colors } from '@theme';

import { styles } from './styles';

type ForgotPasswordProps = NativeStackScreenProps<RootStackNavigator, 'ForgotPassword'>;

export const ForgotPassword = ({ navigation }: ForgotPasswordProps) => {
  useStatusBar('light');
  const i18n = useTranslations();
  const {
    control,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordParams>();

  const forgotPassword = useForgotPassword();
  const isEmpty = !watch('emailOrUsername');

  function onSubmit(values: ForgotPasswordParams) {
    Keyboard.dismiss();

    forgotPassword.mutate(values, {
      onSuccess: (response) => {
        navigation.navigate('ValidateCode', {
          userId: response.id,
          userEmail: values.emailOrUsername,
          type: 'PASSWORD',
        });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data.message;
          toast.danger({
            title: message,
            icon: 'error',
          });
        }
      },
    });
  }

  const textAlign = isDesktop && !isWebResponsive ? 'center' : 'left';

  return (
    <Layout
      withScroll
      verticallyCentered={isDesktop && !isWebResponsive}
      backgroundColor={isDesktop ? Colors.blackThree : undefined}>
      <View style={styles.container}>
        <GoBackButton />
        <DesktopCenteredContainer style={styles.desktopForm}>
          <View style={styles.body}>
            <CustomText weight="interSemi" align={textAlign} size="xlarge">
              {`${i18n.t('forgot.title_1')} `}
              <CustomText weight="interSemi" size="xlarge" color="limeGreen">
                {i18n.t('forgot.title_2')}
              </CustomText>
              {`${'?'}`}
            </CustomText>
            <CustomText customStyle={styles.description} weight="interSemi" align={textAlign} size="xbig">
              {i18n.t('forgot.enter_email')}
            </CustomText>
            <Field
              icon={EmailIcon}
              autoFocus
              name="emailOrUsername"
              type="email-address"
              control={control}
              placeholder={i18n.t('forgot.email_placeholder')}
              getValues={getValues}
              rules={{
                required: i18n.t('forgot.required'),
                pattern: {
                  value: emailRegex,
                  message: i18n.t('forgot.email_pattern_error'),
                },
              }}
              error={errors}
            />
          </View>
          <View style={styles.footer}>
            <Button
              disabled={isEmpty}
              loading={forgotPassword.isLoading}
              customStyle={styles.button}
              onPress={handleSubmit(onSubmit)}>
              {i18n.t('forgot.button_action')}
            </Button>
          </View>
        </DesktopCenteredContainer>
      </View>
    </Layout>
  );
};
