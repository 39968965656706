import React, { useEffect } from 'react';
import Animated, { FadeInLeft, FadeOutLeft } from 'react-native-reanimated';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/core';

import DeleteAccountCatIcon from '@assets/svg/DeleteAccountCatIcon';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import { useDeleteAccount } from '@services/useDeleteAccount';
import Spinner from '@atoms/Spinner';
import Button from '@atoms/Button';
import toast from '@utils/toast';

import { styles } from '../styles';
import { rootProp } from 'app/navigation/types';
import { Step2Props } from './types';

const Step2 = ({ token, password, previousStep }: Step2Props): JSX.Element => {
  const i18n = useTranslations();
  const textAlign = !isDesktop && isWebResponsive ? 'left' : 'center';
  const deleteAccount = useDeleteAccount(token || '');
  const [deletedAccount, setDeletedAccount] = React.useState(false);

  useEffect(() => {
    deleteAccount.mutate(
      {
        password
      },
      {
        onSuccess: () => {
          setDeletedAccount(true);
        },
        onError: () => {
          setDeletedAccount(true);
          toast.danger({
            title: i18n.t('account_settings.delete_account_error'),
            icon: 'error'
          });
          previousStep();
        }
      }
    );
  }, []);

  return (
    <Animated.View style={styles.wrapper} entering={FadeInLeft} exiting={FadeOutLeft}>
      {!deletedAccount ? <Loading /> : null}
      {deletedAccount && (
        <>
          <CustomText
            align={textAlign}
            weight="interSemi"
            size={isWebResponsive ? 'big' : 'mlarge'}
            color="limeGreen">
            {i18n.t('account_settings.delete_account_title_3')}
          </CustomText>

          <CustomText
            customStyle={styles.description}
            align={textAlign}
            weight="interMedium"
            size={isWebResponsive ? 'xmedium' : 'big'}
            color="white">
            {i18n.t('account_settings.delete_account_description_3')}
          </CustomText>

          <View style={[styles.deleteCat, isWebResponsive ? { transform: [{ scale: 0.85 }] } : {}]}>
            <DeleteAccountCatIcon />
          </View>

          <Step2Buttons />
        </>
      )}
    </Animated.View>
  );
};

const Loading = () => {
  const i18n = useTranslations();

  return (
    <View
      style={{
        flex: 1,
        justifyContent: isWebResponsive ? 'flex-start' : 'center',
        alignItems: 'center'
      }}>
      <CustomText
        align="center"
        weight="interSemi"
        size="large"
        color="white"
        customStyle={{ marginBottom: '24px' }}>
        {i18n.t('account_settings.delete_account_title_2')}
      </CustomText>
      <Spinner />
    </View>
  );
};

export const Step2Buttons = () => {
  const i18n = useTranslations();
  const { navigate } = useNavigation<rootProp>();

  const goToRegister = () => {
    navigate('Register');
  };

  const goToHome = () => {
    navigate('Home');
  };

  return (
    <View style={styles.buttonWrapper}>
      <Button
        customStyle={styles.deleteAccountButton}
        customTextStyle={styles.footerButtonText}
        onPress={goToHome}
        secondary>
        {i18n.t('account_settings.go_back_to_home')}
      </Button>
      <Button
        customStyle={styles.deleteAccountButton}
        customTextStyle={styles.footerButtonText}
        onPress={goToRegister}>
        {i18n.t('account_settings.create_new_account')}
      </Button>
    </View>
  );
};

export default Step2;
