import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { CompositeScreenProps } from '@react-navigation/native';
import { DrawerScreenProps } from '@react-navigation/drawer';

import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import FullnameIcon from '@assets/svg/FullnameIcon';
import Button from '@atoms/Button';
import { FooterButton } from '@atoms/FooterButton';
import { authTokenSelector, setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import { AccountSettingsNavigatorParamList, RootStackNavigator } from 'app/navigation/types';
import { useEditUserInfo } from '@services/useEditUserInfo';
import toast from '@utils/toast';
import { onlyStringsRegex } from '@utils/validations';
import { useUserData } from '@services/useUserData';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';

import { styles } from '../styles';
import { ScreenTitle } from '..';

type ChangeNameProps =
  | NativeStackScreenProps<RootStackNavigator, 'ChangeName'>
  | CompositeScreenProps<
      DrawerScreenProps<AccountSettingsNavigatorParamList, 'ChangeName'>,
      NativeStackScreenProps<RootStackNavigator>
    >;

interface ChangeNameParams {
  newFullName: string;
}

const ChangeName = ({ navigation }: ChangeNameProps) => {
  const authToken = useStore(authTokenSelector);
  const { data: userData, isLoading: isLoadingData } = useUserData(authToken || '');

  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);
  const i18n = useTranslations();
  const updateInfo = useEditUserInfo(authToken || '');

  const cancelChangeName = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const {
    control,
    getValues,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm<ChangeNameParams>({ mode: 'onChange' });

  const newFullName = watch('newFullName');

  const isDisabled =
    newFullName === userData?.fullName || !newFullName || Boolean(errors?.newFullName?.message);

  function handleGoBack(): void {
    if (isWebResponsive) {
      navigation.goBack();
      return;
    }
    navigation.replace('AccountSettings');
  }

  const onSubmit = (values: ChangeNameParams) => {
    toggleModal();
    setModalInfo(undefined);
    const dataToSend = {
      newFullName: values.newFullName
    };
    updateInfo.mutate(dataToSend, {
      onSuccess: () => {
        toast.success({ icon: 'success', title: i18n.t('account_settings.changes_saved') });
        handleGoBack();
      },
      onError: () => {
        toast.danger({ icon: 'error', title: i18n.t('account_settings.changes_not_saved') });
        handleGoBack();
      }
    });
  };

  const handleChangeName = () => {
    setModalInfo({
      message: i18n.t('account_settings.change_confirm_message'),
      confirmText: i18n.t('account_settings.change_confirm_text'),
      cancelText: i18n.t('account_settings.change_cancel_text'),
      confirmAction: handleSubmit(onSubmit),
      cancelAction: () => cancelChangeName(),
      bottomFixed: false
    });
    toggleModal();
  };

  return (
    <Layout
      padding={false}
      hasBottomCTAS={
        <FooterButton>
          <Button
            loading={updateInfo.isLoading}
            disabled={isDisabled}
            customStyle={styles.footerButton}
            customTextStyle={styles.footerButtonText}
            onPress={handleChangeName}>
            {i18n.t('account_settings.change')}
          </Button>
        </FooterButton>
      }
      verticallyCentered={isDesktop}>
      <View style={styles.screenContainer}>
        <View style={styles.screenInnerContainer}>
          {isMobile || isWebResponsive ? (
            <View style={styles.goBack}>
              <GoBackButton goBackAction={handleGoBack} />
            </View>
          ) : null}
          <ScreenTitle>{i18n.t('account_settings.change_name')}</ScreenTitle>
          <View style={styles.formBody}>
            <Field
              editable={false}
              error={errors}
              icon={FullnameIcon}
              name="oldFullName"
              control={control}
              getValues={getValues}
              defaultValue={userData?.fullName}
              loading={isLoadingData}
            />
            <Field
              error={errors}
              icon={FullnameIcon}
              placeholder={i18n.t('account_settings.new_fullname_placeholder').toString()}
              name="newFullName"
              control={control}
              getValues={getValues}
              rules={{
                required: i18n.t('register.required').toString(),

                minLength: {
                  value: 3,
                  message: i18n.t('register.fullname_min_length_error')
                },
                pattern: {
                  value: onlyStringsRegex,
                  message: i18n.t('register.fullname_pattern_error')
                },
                maxLength: {
                  value: 25,
                  message: i18n.t('register.fullname_max_length_error')
                }
              }}
              autoCapitalize="words"
              loading={isLoadingData}
            />
            {isDesktop && !isWebResponsive ? (
              <Button
                loading={updateInfo.isLoading}
                disabled={isDisabled}
                customStyle={styles.footerButton}
                customTextStyle={styles.footerButtonText}
                onPress={handleChangeName}>
                {i18n.t('account_settings.change')}
              </Button>
            ) : null}
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default ChangeName;
