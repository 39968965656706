import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    borderWidth: 3,
    borderColor: Colors.limeGreen,
    position: 'absolute',
    borderRadius: moderateScale(10),
    zIndex: 99,
    overflow: 'hidden'
  },
  box: {
    width: moderateScale(150),
    position: 'absolute',
    backgroundColor: Colors.limeGreen,
    transform: [{ rotate: '40deg' }],
    justifyContent: 'center',
    alignItems: 'center'
  }
});
