import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { isDesktop, isMobile, isWebResponsive, SCREEN_WIDTH, WINDOW_WIDTH } from '@constants/platform';
import { Colors, Fonts, Spacing } from '@theme';

export const customStyle = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: isWebResponsive ? 0 : Spacing.XLARGE,
    minHeight: 420,
    maxHeight: isWebResponsive ? 840 : 600
  }
});

export const styles = StyleSheet.create({
  body:
    isMobile || isWebResponsive
      ? {
          flex: 1,
          marginTop: moderateScale(26),
          width: '100%',
          marginHorizontal: 'auto'
        }
      : {
          flex: 1,
          paddingTop: Spacing.XLARGE,
          paddingBottom: isMobile ? Spacing.XLARGE : 0
        },
  formBody:
    isMobile || isWebResponsive
      ? {
          flex: 1,
          marginTop: moderateScale(26),
          width: '90%',
          marginHorizontal: 'auto'
        }
      : {
          flex: 1,
          paddingTop: Spacing.XLARGE,
          paddingBottom: isMobile ? Spacing.XLARGE : 0
        },
  goBack: {
    position: 'absolute',
    top: moderateScale(10),
    left: moderateScale(20),
    zIndex: 3
  },
  skeletonItem: {
    marginBottom: moderateScale(20)
  },
  footerButton: {
    width: isWebResponsive ? SCREEN_WIDTH - Spacing.LATERAL : undefined,
    paddingVertical: isDesktop ? moderateScale(14) : 0,
    paddingHorizontal: Spacing.XLARGE,
    marginHorizontal: 'auto',
    ...(isDesktop && !isWebResponsive
      ? {
          alignSelf: 'flex-start',
          marginTop: Spacing.XLARGE
        }
      : { alignSelf: 'flex-start', marginVertical: 0 })
  },
  footerButtonText: {
    fontSize: moderateScale(16),
    width: '100%',
    overflow: 'visible',
    textAlign: 'center'
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  confirmButton: {
    fontSize: Fonts.SIZES.xMedium
  },
  button: {
    width: '49%',
    paddingVertical: moderateScale(13)
  },
  cancelModal: {
    backgroundColor: Colors.darkGrey,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: moderateScale(15),
    borderRadius: moderateScale(14)
  },
  modalTitle: {
    marginBottom: moderateScale(20)
  },
  passInput: { backgroundColor: Colors.greyFour },
  deleteCat: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  contact: {
    textDecorationLine: 'underline',
    top: moderateScale(2)
  },
  contactWrapper: {
    width: '100%',
    marginVertical: '16px'
  },
  wrapper: {
    flex: 1
  },
  description: {
    lineHeight: moderateScale(22),
    marginVertical: moderateScale(16)
  },
  buttonWrapper: {
    flexDirection: isWebResponsive ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: isWebResponsive ? 'center' : 'space-between',
    width: isWebResponsive ? SCREEN_WIDTH - Spacing.LATERAL * 2 : '100%'
  },

  deleteAccountActionButton: {
    width: isMobile ? SCREEN_WIDTH - Spacing.LATERAL * 2 : '100%',
    paddingVertical: moderateScale(14),
    paddingHorizontal: isDesktop ? Spacing.XLARGE : undefined,
    marginBottom: moderateScale(20)
  },
  deleteAccountButton: {
    width: isWebResponsive ? SCREEN_WIDTH - Spacing.LATERAL * 2 : '48%',
    paddingVertical: moderateScale(14),
    paddingHorizontal: isWebResponsive ? Spacing.XLARGE : undefined,
    marginBottom: moderateScale(20)
  },
  screenContainer:
    isDesktop && !isWebResponsive
      ? {
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          minWidth: 'fit-content',
          paddingTop: Spacing.XLARGE
        }
      : { flex: 1, paddingTop: Spacing.TINY },
  screenInnerContainer: isWebResponsive
    ? {
        flex: 1,
        alignItems: 'center',
        width: '100%'
      }
    : { flex: 1, maxWidth: moderateScale(500) }
});
