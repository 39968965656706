import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';

import { socialBackground } from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: moderateScale(20)
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: { marginBottom: moderateScale(20) },
  itemContainer: {
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'baseline'
  },
  item: {
    width: moderateScale(254),
    height: moderateScale(235),
    borderRadius: 16,
    overflow: 'hidden',
    marginBottom: moderateScale(24),
    marginRight: moderateScale(24)
  },
  gradient: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    borderRadius: moderateScale(10)
  },
  text: { zIndex: 3, position: 'absolute', paddingVertical: 16, paddingHorizontal: 14, bottom: 0 },
  tooltipContainer: {
    position: 'absolute',
    maxWidth: moderateScale(450),
    zIndex: 5,
    top: 40
  },
  tooltipBackground: {
    padding: 20,
    backgroundColor: socialBackground,
    borderRadius: 20
  },
  tooltipIndicator: {
    position: 'absolute',
    width: 14,
    height: 14,
    top: -7,
    left: 40,
    backgroundColor: socialBackground,
    transform: [{ rotate: '45deg' }]
  },
  tooltipTitle: {
    flexDirection: 'row',
    marginBottom: 10
  },
  icon: { marginRight: 6 },
  emptyContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  lottie: {
    width: moderateScale(207)
  }
});
