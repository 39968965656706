import React, { useState } from 'react';
import { LayoutChangeEvent, Linking, Pressable, View } from 'react-native';
import { Link } from '@react-navigation/native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Button from '@atoms/Button';
import RoundedButton from '@atoms/RoundedButton';
import EmailFillIcon from '@assets/svg/EmailFillIcon';
import InstagramIcon from '@assets/svg/InstagramIcon';
import FacebookIcon from '@assets/svg/FacebookIcon';
import TwitterIcon from '@assets/svg/TwitterIcon';
import PlayStoreIcon from '@assets/svg/PlayStoreIcon';
import { URLs } from '@utils/shareApp';
import { authTokenSelector, useStore } from '@store/index';
import { Spacing } from '@theme';
import AppStoreIcon from '@assets/svg/AppStoreIcon';
import { isMobile } from '@constants/platform';
import links from '@constants/links';
import { useUploadingGameLink } from '@hooks/useUploadingGameLink';
import GetLogo from '@molecules/GatoLogo';

import { styles, storeSectionStyles, creatorSectionStyles, mediaStyles } from './styles';
import { OUR_SOCIAL_MEDIA, SectionProps, SocialMedia } from './types';

const StoresSection = ({ isSmallLayout }: SectionProps) => {
  const i18n = useTranslations();
  const handleOpenStore = (store: 'android' | 'ios') => {
    Linking.openURL(URLs[store]);
  };

  return (
    <View style={[storeSectionStyles.container, isSmallLayout && styles.columnCenter]}>
      <GetLogo height={50} width={150} />
      <CustomText size="big" color="greyTwo" customStyle={storeSectionStyles.slogan}>
        {i18n.t('desktop_footer.slogan')}
      </CustomText>
      <View style={storeSectionStyles.buttons}>
        <Button
          onPress={() => handleOpenStore('android')}
          secondary
          customStyle={storeSectionStyles.button}
          customTextStyle={storeSectionStyles.buttonText}>
          <CustomText size="small">
            <PlayStoreIcon style={storeSectionStyles.storeIcon} height="100%" />
            {i18n.t('desktop_footer.play_store')}
          </CustomText>
        </Button>
        <Button
          onPress={() => handleOpenStore('ios')}
          secondary
          customStyle={storeSectionStyles.button}
          customTextStyle={storeSectionStyles.buttonText}>
          <CustomText size="small">
            <AppStoreIcon style={storeSectionStyles.storeIcon} height="100%" />
            {i18n.t('desktop_footer.app_store')}
          </CustomText>
        </Button>
      </View>
    </View>
  );
};

const CreatorSection = ({ isSmallLayout }: SectionProps): JSX.Element => {
  // hooks
  const link: string = useUploadingGameLink();
  const i18n = useTranslations();

  // render
  return (
    <View
      style={[
        creatorSectionStyles.container,
        isSmallLayout && styles.columnCenter,
        isSmallLayout && creatorSectionStyles.smallContainer
      ]}>
      <CustomText color="greySix" size="mbig" weight="interBold">
        {i18n.t('desktop_footer.creator_title')}
      </CustomText>
      <CustomText color="greyTwo" size="xmedium" customStyle={creatorSectionStyles.creatorText}>
        {i18n.t('desktop_footer.creator_invite')}
      </CustomText>
      <Button
        onPress={() => Linking.openURL(link)}
        customStyle={[
          creatorSectionStyles.creatorButton,
          !isSmallLayout ? creatorSectionStyles.buttonLeft : {}
        ]}>
        <CustomText color="black" size="lmedium">
          {i18n.t('desktop_navbar.upload_game')}
        </CustomText>
      </Button>
    </View>
  );
};

const MediaSection = ({ isSmallLayout }: SectionProps) => {
  const i18n = useTranslations();
  const alignment = isSmallLayout ? 'center' : 'left';
  const handleSocialCTA = (social: SocialMedia) => {
    Linking.openURL(OUR_SOCIAL_MEDIA[social]);
  };

  return (
    <View>
      <CustomText color="greyTwo" size="lmedium" align={alignment}>
        {i18n.t('desktop_footer.media_title')}
      </CustomText>
      <View style={mediaStyles.mediaButtonsContainer}>
        <RoundedButton action={() => handleSocialCTA('twitter')} customStyle={mediaStyles.mediaButton}>
          <TwitterIcon />
        </RoundedButton>
        <RoundedButton action={() => handleSocialCTA('instagram')} customStyle={mediaStyles.mediaButton}>
          <InstagramIcon />
        </RoundedButton>
        <RoundedButton action={() => handleSocialCTA('facebook')} customStyle={mediaStyles.mediaButton}>
          <FacebookIcon />
        </RoundedButton>
        <RoundedButton action={() => handleSocialCTA('email')} customStyle={mediaStyles.mediaButton}>
          <EmailFillIcon />
        </RoundedButton>
      </View>
    </View>
  );
};

const CompanySection = ({ isSmallLayout }: SectionProps) => {
  const i18n = useTranslations();
  const alignment = isSmallLayout ? 'center' : 'left';

  return (
    <View style={styles.listItem}>
      <CustomText color="greySix" size="lmedium" customStyle={styles.listItem} align={alignment}>
        {i18n.t('desktop_footer.company_title')}
      </CustomText>

      <Pressable onPress={() => Linking.openURL(links.gatoAboutLink)}>
        <CustomText color="greyTwo" size="lmedium" align={alignment}>
          {i18n.t('desktop_footer.about_text')}
        </CustomText>
      </Pressable>
    </View>
  );
};

const AppSection = ({ isSmallLayout }: SectionProps) => {
  const i18n = useTranslations();
  const authToken = useStore(authTokenSelector);
  const alignment = isSmallLayout ? 'center' : 'left';

  return (
    <View style={styles.listItem}>
      <CustomText
        color="greySix"
        size="lmedium"
        customStyle={styles.listItem}
        align={isSmallLayout ? 'center' : 'left'}>
        {i18n.t('desktop_footer.app_title')}
      </CustomText>
      <Link to={isMobile ? '/home' : '/'} style={[styles.listItem, { textAlign: alignment }]}>
        <CustomText color="greyTwo" size="lmedium">
          {i18n.t('desktop_footer.home_text')}
        </CustomText>
      </Link>
      <Link to="/explore" style={[styles.listItem, { textAlign: alignment }]}>
        <CustomText color="greyTwo" size="lmedium">
          {i18n.t('desktop_footer.explore_text')}
        </CustomText>
      </Link>
      {authToken && (
        <Link to="/account-settings" style={[styles.listItem, { textAlign: alignment }]}>
          <CustomText color="greyTwo" size="lmedium">
            {i18n.t('desktop_footer.settings_text')}
          </CustomText>
        </Link>
      )}
      <Link to="/delete-account" style={[styles.listItem, { textAlign: alignment }]}>
        <CustomText color="greyTwo" size="lmedium">
          {i18n.t('account_settings.delete_account')}
        </CustomText>
      </Link>
    </View>
  );
};

const DesktopFooter = () => {
  const i18n = useTranslations();
  const [isSmallLayout, setSmallLayout] = useState(false);

  const handleLayout = (event: LayoutChangeEvent) => {
    const layoutWidth = event.nativeEvent.layout.width;
    setSmallLayout(layoutWidth < 1050);
  };

  return (
    <View style={styles.container} onLayout={handleLayout}>
      <View style={styles.innerContainer}>
        <View style={[styles.top, isSmallLayout && styles.columnCenter]}>
          <StoresSection isSmallLayout={isSmallLayout} />
          <CompanySection isSmallLayout={isSmallLayout} />
          <AppSection isSmallLayout={isSmallLayout} />
          <CreatorSection isSmallLayout={isSmallLayout} />
        </View>
        <View style={[styles.bottom, isSmallLayout && styles.columnCenter]}>
          <MediaSection isSmallLayout={isSmallLayout} />
          <CustomText
            color="greyTwo"
            align="left"
            customStyle={[styles.legal, isSmallLayout ? { marginTop: Spacing.XLARGE } : {}]}>
            {i18n.t('desktop_footer.legal')}
          </CustomText>
        </View>
      </View>
    </View>
  );
};

export default DesktopFooter;
