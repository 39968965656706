import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 25,
    backgroundColor: Colors.darkGrey,
    borderRadius: 16,
    paddingVertical: moderateScale(40),
    paddingHorizontal: moderateScale(64),
    width: '48%',
    minWidth: moderateScale(405),
    height: moderateScale(256),
    justifyContent: 'space-between'
  },
  flexWrapper: { flexDirection: 'row', alignItems: 'center' }
});
