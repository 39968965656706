import React, { useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Slider from '@organisms/Slider';
import Onboarding2 from '@assets/svg/OnboardingDesktop2';
import Onboarding1 from '@assets/svg/OnboardingDesktop1';
import { Spacing } from '@theme';

import { styles } from './styles';

const Onboarding = () => {
  const i18n = useTranslations();
  const [containerWidth, setContainerWidth] = useState(550);

  const onboardingSteps = [
    {
      image: <Onboarding1 width="90%" height="auto" style={styles.onboarding1} />,
      title: i18n.t(`onboarding.title_1`),
      description: i18n.t(`onboarding.description_1`)
    },
    {
      image: <Onboarding2 width="90%" height="auto" style={styles.onboarding2} />,
      title: i18n.t(`onboarding.title_2`),
      description: i18n.t(`onboarding.description_2`)
    }
  ];

  const handleLayout = (event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  };

  const bannerDimentions = { height: containerWidth * 0.9, width: containerWidth };

  const renderStep = (item: { image: JSX.Element; title: string; description: string }) => (
    <View style={bannerDimentions}>
      <View>{item.image}</View>
      <Animated.View style={styles.itemText} entering={FadeIn} exiting={FadeOut}>
        <CustomText size="mbig" customStyle={styles.title} weight="interMedium">
          {item.title}
        </CustomText>
        <CustomText customStyle={styles.description} size="xmedium" color="whiteTwo" weight="interMedium">
          {item.description}
        </CustomText>
      </Animated.View>
    </View>
  );

  return (
    <View style={styles.container} onLayout={(event) => handleLayout(event)}>
      <View style={[{ width: containerWidth }, styles.innerContainer]}>
        <Slider
          directSale={false}
          banners={onboardingSteps}
          customSlide={renderStep}
          pagination
          qtyToSlide={1}
          bannerDimentions={bannerDimentions}
          arrows
          bannersGap={Spacing.MEDIUM * 3}
          containerStyles={styles.innerContainer}
        />
      </View>
    </View>
  );
};

export default Onboarding;
