import React from 'react';
import { FlatList, Pressable, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { CommonActions, useNavigation } from '@react-navigation/core';

import CustomText from '@atoms/CustomText';
import { useFollows } from '@services/useFollows';
import { useUpdateFollow } from '@services/useUpdateFollow';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { RootStackNavigator } from 'app/navigation/types';
import CommentSkeleton from '@molecules/CommentSkeleton';
import RoundedButton from '@atoms/RoundedButton';
import CloseIcon from '@assets/svg/CloseIcon';

import { styles } from './styles';
import { useTranslations } from '@hooks/useTranslation';

type FollowsProps = NativeStackScreenProps<RootStackNavigator, 'Follows'>;

const Follows = ({ type, userId, authToken, closeModal }: FollowsProps) => {
  const i18n = useTranslations();
  const navigation = useNavigation();

  const { data = [], isLoading } = useFollows(type, userId, authToken);
  const updateFollow = useUpdateFollow(authToken);

  const handleFollow = (id: number) => {
    updateFollow.mutate(id);
  };

  const handleUser = (userName: number) => {
    if (closeModal) closeModal();
    const navigateAction = CommonActions.navigate({
      name: 'UserProfile',
      params: {
        userName
      },
      key: `UserProfile-${userName}`
    });
    navigation.dispatch(navigateAction);
  };

  const handlePressUser = (item: User) => {
    if (closeModal) closeModal();
    if (item?.isOwnProfile) {
      navigation.navigate('MyProfile');
    }
    return handleUser(item?.userName);
  };

  const title = type === 'followers' ? i18n.t('profile.followers') : i18n.t('profile.following');

  return (
    <>
      <View style={styles.header}>
        <CustomText weight="interSemi" size="mbig" color="white" align="center" customStyle={styles.title}>
          {title}
        </CustomText>
        <RoundedButton action={closeModal}>
          <CloseIcon />
        </RoundedButton>
      </View>
      <FlatList
        showsVerticalScrollIndicator
        data={data}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <View style={styles.container}>
            {isLoading ? (
              <>
                <CommentSkeleton />
                <CommentSkeleton />
                <CommentSkeleton />
              </>
            ) : (
              <>
                <Pressable onPress={() => handlePressUser(item)}>
                  <View style={styles.btn}>
                    <AvatarProfile
                      customContainerStyle={styles.avatarStyle}
                      imageUrl={item?.profileImage}
                      backgroundUrl={item?.backgroundProfileImage ?? undefined}
                      offsetKitten={0}
                    />
                    <View>
                      <CustomText
                        weight="interSemi"
                        align="left"
                        customStyle={{ maxWidth: '25ch' }}
                        numberOfLines={1}>
                        {item.fullName}
                      </CustomText>
                      <CustomText
                        weight="interSemi"
                        size="small"
                        color="greySix"
                        align="left">{`@${item.userName}`}</CustomText>
                    </View>
                  </View>
                </Pressable>
                {!item?.isOwnProfile && (
                  <Pressable disabled={updateFollow.isLoading} onPress={() => handleFollow(item.id)}>
                    <View
                      style={[
                        styles.ctaFollow,
                        item.isFollowByYou ? styles.unfollow : styles.follow,
                        // eslint-disable-next-line react-native/no-inline-styles
                        { opacity: updateFollow.isLoading ? 0.8 : 1 }
                      ]}>
                      <CustomText weight="interSemi">
                        {item.isFollowByYou ? i18n.t('profile.unfollow') : i18n.t('profile.follow')}
                      </CustomText>
                    </View>
                  </Pressable>
                )}
              </>
            )}
          </View>
        )}
      />
    </>
  );
};

export default Follows;

export interface User {
  id: number;
  email: string;
  userName: string;
  fullName: string;
  role: string;
  type: string;
  bio: string;
  likes: number;
  games: number;
  followers: number;
  following: number;
  backgroundProfileImage: null;
  profileImage: string;
  created: string;
  verified: boolean;
  isOwnProfile: boolean;
  isFollowByYou: boolean;
}
