import React, { FC } from 'react';
import { View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { styles } from './styles';
import { useTranslations } from '@hooks/useTranslation';
import { moderateScale } from '@utils/scaling';

const NewGameTag: FC<INewGameTagProps> = ({ bigText }) => {
  const i18n = useTranslations();
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.box,
          {
            right: bigText ? moderateScale(-52) : moderateScale(-56),
            top: bigText ? moderateScale(-22) : moderateScale(-10),
            height: bigText ? moderateScale(76) : moderateScale(45)
          }
        ]}>
        <CustomText
          size={bigText ? 'big' : 'msmall'}
          weight="interBold"
          color="blackTwo"
          customStyle={{ marginTop: moderateScale(bigText ? 40 : 20), zIndex: 99999 }}>
          {i18n.t('explore.new_game')}
        </CustomText>
      </View>
    </View>
  );
};

export default NewGameTag;

interface INewGameTagProps {
  bigText?: boolean;
}
