import { StyleSheet } from 'react-native';

import { TABBAR_HEIGHT } from '@constants/platform';
import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling.web';

export const styles = StyleSheet.create({
  mainContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 999,
    bottom: 0,
    left: 0,
    height: TABBAR_HEIGHT * 2.6,
    backgroundColor: Colors.greyFour,
    paddingVertical: 20,
    paddingHorizontal: 24,
    justifyContent: 'space-between'
  },
  textWrapper: { flexDirection: 'row', alignItems: 'center' },
  text: { color: Colors.white, fontSize: 14, marginHorizontal: 10 },
  buttonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginVertical: 12
  },
  primaryButtonText: { fontSize: moderateScale(14) },
  button: { width: '48%', borderRadius: 16 },
  secondaryButtonText: { color: Colors.white, fontSize: moderateScale(14) }
});
