import React, { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import { HEADER_HEIGHT, isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import { authTokenSelector, useStore } from '@store/index';

import { customStyle, styles } from './styles';
import { DeleteAccountParams, DeleteAccountProps, DeleteAccountStepEnum, INITIAL_VALUE } from './types';
import Step1 from './Step1';
import Step2 from './Step2';
import { useIsFocused } from '@react-navigation/core';

const DeleteAccountWithoutLogin = ({ navigation }: DeleteAccountProps) => {
  // states
  const [step, setStep] = useState<DeleteAccountStepEnum>(INITIAL_VALUE);
  const [authToken, setAuthToken] = useState<string>();

  // hooks
  const {
    control,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<DeleteAccountParams>();

  // constants
  const isFocused: boolean = useIsFocused();
  const isLogged: boolean = !!useStore(authTokenSelector);
  const password: string = watch('password');
  const isEmpty: boolean = !watch('emailOrUsername') || !watch('password');

  // functions
  function handleGoBack(): void {
    navigation.goBack();
  }

  function handleFormSteps(): void {
    setStep(DeleteAccountStepEnum.DELETE_SUCCESS);
  }
  function handleResetForm(): void {
    reset();
    setStep(INITIAL_VALUE);
  }

  // effects
  if (isLogged) {
    navigation.navigate('AccountSettings', { screen: 'DeleteAccount' });
  }
  useEffect(() => {
    return handleResetForm();
  }, [isFocused]);

  // render
  return (
    <Layout
      padding={false}
      withScroll={isDesktop && !isWebResponsive}
      verticallyCentered={isDesktop}
      showScrollIndicator={false}>
      <View style={styles.screenContainer}>
        <View style={styles.screenInnerContainer}>
          {!isDesktop && isWebResponsive ? <GoBackAndTitle handleGoBack={handleGoBack} /> : null}
          <View
            style={[
              styles.body,
              isDesktop && !isWebResponsive ? customStyle.body : { paddingHorizontal: 20 }
            ]}>
            {step === DeleteAccountStepEnum.DELETE_FORM ? (
              <Step1
                control={control}
                getValues={getValues}
                errors={errors}
                isEmpty={isEmpty}
                handleSubmit={handleSubmit}
                setAuthToken={setAuthToken}
                nextStep={handleFormSteps}
              />
            ) : null}

            {step === DeleteAccountStepEnum.DELETE_SUCCESS ? (
              <Step2 token={authToken} password={password} previousStep={handleResetForm} />
            ) : null}
          </View>
        </View>
      </View>
    </Layout>
  );
};

const GoBackAndTitle = ({ handleGoBack }: { handleGoBack: () => void }) => {
  const i18n = useTranslations();

  return (
    <>
      <View style={styles.goBack}>
        <GoBackButton goBackAction={handleGoBack} />
      </View>
      <ScreenTitle>{i18n.t('account_settings.delete_account')}</ScreenTitle>
    </>
  );
};

const ScreenTitle = ({ children }: { children: ReactNode }) =>
  !isDesktop && isWebResponsive ? (
    <CustomText
      size="xmedium"
      weight="interSemi"
      color="greySix"
      customStyle={{ marginTop: HEADER_HEIGHT / 4 }}>
      {children}
    </CustomText>
  ) : (
    <CustomText size="large" weight="interSemi" align="left">
      {children}
    </CustomText>
  );

export default DeleteAccountWithoutLogin;
